import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const ProfilePic = styled.img`
  width: 91px;
  height: 91px;
  object-fit: cover;
  border-radius: 50%;
  background-color: white;
  margin-bottom: 2px;
  position: relative;
`;

export const Container = styled.div`
  max-width: 1300px;
  /* margin: 0 auto; */
  padding: 0 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 20px 53px;
  }
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 30px;
  &:nth-child(8),
  &:nth-child(9) {
    margin-top: 0;
  }

  &:nth-child(2),
  &:nth-child(4),
  &:nth-child(6),
  &:nth-child(9),
  &:nth-child(11),
  &:nth-child(13) {
    padding-left: 15px;
  }

  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
    margin-top: 0;
    padding-left: 0 !important;
  }
`;

export const Label = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;
export const Value = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  word-break: break-all;
  padding-right: 6px;
`;

export const Row = styled.div`
  width: calc(1100px / 2);
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
`;

export const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  padding-left: 29px;
`;

export const Bold = styled.span`
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const BoldLabel = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 24px;
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 12px;
    margin-top: 58px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const ProfilePicContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  padding: 25px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: start;
  width: 50%;
  @media ${theme?.breakpoints?.sm_up} {
    display: grid;
    width: 100%;
    grid-column: 1 / span 3;
    margin-bottom: 30px;
  }
`;

export const Address = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  line-height: 1.7;
  color: ${COLORS.COLOR_DARK};
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 0;
  }
`;
