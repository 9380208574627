import React from "react";
import { BasicDetailsLabel, BasicDetailsValue } from "../PartyDetails/styles";
import labels from "../../../helpers/labels.json";
import styled from "styled-components";

export const CartSplitUp = ({ data }) => {
  const { fee } = data;
  return (
    <>
      <Container>
        <SubContainer>
          <BasicDetailsLabel>{labels["case_fees"]}</BasicDetailsLabel>
          <BasicDetailsValue>
            {fee?.unit} {Number(fee?.listPrice).toFixed(2)}
          </BasicDetailsValue>
        </SubContainer>
        <SubContainer>
          <BasicDetailsLabel>{labels["cgst"]}</BasicDetailsLabel>
          <BasicDetailsValue>
            {fee?.unit} {Number(fee?.cgst).toFixed(2)}
          </BasicDetailsValue>
        </SubContainer>
        <SubContainer>
          <BasicDetailsLabel>{labels["sgst"]}</BasicDetailsLabel>
          <BasicDetailsValue>
            {fee?.unit} {Number(fee?.sgst).toFixed(2)}
          </BasicDetailsValue>
        </SubContainer>
        <SubContainer>
          <BasicDetailsLabel>{labels["case_igst"]}</BasicDetailsLabel>
          <BasicDetailsValue>
            {fee?.unit} {Number(fee?.igst).toFixed(2)}
          </BasicDetailsValue>
        </SubContainer>
        <SubContainer>
          <BasicDetailsLabel>{labels["total_payment"]}</BasicDetailsLabel>
          <BasicDetailsValue>
            {fee?.unit} {Number(fee?.total).toFixed(2)}
          </BasicDetailsValue>
        </SubContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (min-width: 780px) {
    margin: 30px 0 30px 50px;
    flex-wrap: nowrap;
  }
`;

const SubContainer = styled.div`
  width: 50%;
  margin-bottom: 25px;

  padding-left: 28px;
  &:nth-child(even) {
    padding-left: 18px;
    text-align: center;
  }
  &:nth-child(5) {
    margin-bottom: 0;
  }
  @media (min-width: 780px) {
    width: auto;
    margin: 0 30px;
    padding: 0;
    text-align: start;
    &:nth-child(even) {
      padding: 0;
      text-align: start;
    }
  }
`;
