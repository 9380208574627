import API from "../api/manager";
import config from "../api/config";

const setConfig = (data) => {
  return API({
    method: "POST",
    url: config.urls.systemConfig,
    data,
  });
};

const getConfig = () => {
  return API(
    {
      method: "GET",
      url: config.urls.systemConfig,
    },
    false
  );
};

const getProfile = (id) => {
  return API({
    method: "GET",
    url: config.urls.user.index + `/${id}`,
  });
};

export default {
  getConfig,
  setConfig,
  getProfile,
};
