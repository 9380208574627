import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import FormField from "../../common/FormField/FormField";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../common/Buttons";
import useLoader from "../../../hooks/useLoader";
import { Formik } from "formik";
import { OfflinePaymentSchema } from "../../../helpers/validationSchema";
import { useSnackbar } from "notistack";
import { getErrorMessage, getFileName } from "../../../helpers/functions";
import labels from "../../../helpers/labels.json";
import _ from "lodash";
import { Modal } from "@material-ui/core";
import { CustomFileUpload } from "../../common/FormInputs";
import AWSService from "../../../services/AWSService";
import CartService from "../../../services/CartService";

export default function PaymentModal({ modal, setModal, id }) {
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values) {
    try {
      setLoader({ state: true, message: "Updating payment..." });
      const postbody = { ...values };
      const response = await CartService.captureOfflineParties(
        modal.data.id,
        postbody
      );
      if (response.message) {
        enqueueSnackbar(response.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
    }
  }

  async function s3Upload(file, field, setFieldValue, setFieldError) {
    if (file) {
      try {
        setLoader({ state: true, message: `Uploading file ${file.name} ...` });
        const payload = {
          key: file.name,
        };
        const s3Response = await AWSService.getS3URL(payload);
        if (s3Response.url) {
          const res = await AWSService.uploadToS3(s3Response.url, file);
          setFieldValue(field, res);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        // setFieldError(field, message);
        enqueueSnackbar(message, { variant: "error" });
      } finally {
        setLoader({ state: false });
      }
    }
  }

  return (
    <Modal open={modal.state} onClose={() => setModal({ state: false })}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{"Pay Offline"}</Heading>
          <CloseModal
            onClick={() => setModal({ state: false })}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <Formik
            initialValues={{
              paymentType: "Cheque",
              details: "",
              paymentRelatedDocuments: "",
            }}
            enableReinitialize
            validateOnBlur
            validationSchema={OfflinePaymentSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur,
            }) => (
              <>
                <FormField
                  input={{
                    name: "paymentType",
                    label: _.upperCase(labels.payment_type),
                    type: "select",
                  }}
                  menuItems={["Cheque", "Transfer", "Others"].map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  {...{
                    touched,
                    errors,
                    values,
                    handleChange,
                    handleBlur,
                  }}
                />
                <div style={{ marginTop: 17 }}>
                  <FormField
                    input={{
                      name: "details",
                      label: _.upperCase(labels.details),
                      type: "textarea",
                      placeholder: "",
                    }}
                    style={{ backgroundColor: "#f9f9f9" }}
                    multiline
                    maxLength={0}
                    {...{
                      touched,
                      errors,
                      values,
                      handleChange,
                      handleBlur,
                    }}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <Label>Upload payment related documents(if any)</Label>
                  <CustomFileUpload
                    file={{ name: getFileName(values.paymentRelatedDocuments) }}
                    style={{ marginTop: 6, width: "100%" }}
                    setFile={(file) =>
                      s3Upload(
                        file,
                        "paymentRelatedDocuments",
                        setFieldValue,
                        setFieldError
                      )
                    }
                    fileUrl={values.paymentRelatedDocuments}
                    accept=".pdf, .docx, .txt"
                  />
                </div>
                <Flex>
                  <HalfWidth>
                    <PrimaryOutlinedCTAButton
                      onClick={() => setModal({ state: false })}
                    >
                      {"Cancel"}
                    </PrimaryOutlinedCTAButton>
                  </HalfWidth>
                  <HalfWidth>
                    <PrimaryCTAButton onClick={handleSubmit}>
                      {"Submit"}
                    </PrimaryCTAButton>
                  </HalfWidth>
                </Flex>
              </>
            )}
          </Formik>
        </AgentFormContainer>
      </DrawerContainer>
    </Modal>
  );
}

const DrawerContainer = styled.div`
  max-width: 95%;
  min-height: 318px;
  width: 100%;
  background-color: white;
  outline: none;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 516px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 14px;
  padding-left: 54px;
  padding-right: 54px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 47px;
`;

const HalfWidth = styled.div`
  width: 48%;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
`;
