import React from "react";
import Dialog from "@material-ui/core/Dialog";
import { PrimaryCTAButton, PrimaryOutlinedCTAButton } from "../Buttons";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

const AlertDialog = ({
  heading = "Quit Case",
  desc = "Are you sure you want to Quit this case? All the negotiation progress will be lost if you quit case at this point",
  primaryBtnText = "Submit",
  secondaryBtnText = "Close",
  clickSecondarybtn = () => {},
  clickPrimaryBtn = () => {},
  isOpen = false,
  onDialogClose = () => {},
  descriptionTextStyle = {}
}) => {
  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={onDialogClose}
        disableRestoreFocus={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Heading>{heading}</Heading>
        <Description
          style={{
            ...descriptionTextStyle
          }}
        >
          {desc}
        </Description>
        <ButtonWrapper>
          {secondaryBtnText && (
            <div className="btn">
              <PrimaryOutlinedCTAButton onClick={clickSecondarybtn}>
                {secondaryBtnText}
              </PrimaryOutlinedCTAButton>
            </div>
          )}
          {primaryBtnText && (
            <div className="btn">
              <PrimaryCTAButton onClick={clickPrimaryBtn}>
                {primaryBtnText}
              </PrimaryCTAButton>
            </div>
          )}
        </ButtonWrapper>
      </Dialog>
    </div>
  );
};
export default AlertDialog;

const ButtonWrapper = styled.div`
  width: 100%;
  margin: 20px 0px;
  display: flex;
  flex-direction: column;
  & .btn {
    width: 100%;
    padding: 10px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    margin: 20px auto 30px;
    flex-direction: row;
    justify-content: center;
    & .btn {
      width: 192px;
      padding: 10px;
    }
  }
`;

const Heading = styled.div`
  font-family: ${theme.fonts.primaryFontExtraBold};
  color: ${COLORS.COLOR_DARK};
  margin: 20px;
  font-size: 16px;
  text-align: center;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 30px 0px;
    font-size: 18px;
  }
`;

const Description = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  line-height: 1.71;
  color: ${COLORS.COLOR_DARK};
  text-align: center;
  padding: 10px 20px;
  width: 100%;
  min-width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 20px 62px;
    font-size: 14px;
    min-width: 440px;
  }
`;

// const CustomTextArea = styled(TextareaAutosize)`
//   margin-bottom: 25px;
//   margin-right: 20px;
//   margin-left: 25px;
//   color: ${COLORS.COLOR_DARK} !important;
//   font-family: ${theme.fonts.primaryFontSemiBold};
//   /* min-height: 89px; */
//   border-radius: 6px;
//   border: solid 0.5px #e1e3ee;
//   background-color: #ffffff;
//   outline: none;
//   padding: 15px;
//   :hover {
//     border: solid 0.5px black;
//   }
//   @media ${theme?.breakpoints?.sm_up} {
//     width: 450px;
//     height: 100px;
//     margin: 20px auto 30px;
//     flex-direction: row;
//     justify-content: center;
//   }
// `;

// const Label = styled(FormLabel)`
//   font-family: ${theme.fonts.primaryFontSemiBold};
//   color: ${COLORS.INPUT_LABEL} !important;
//   font-size: 12px;
//   line-height: 2.1;
//   margin-left: 30px;
//   margin-right: 30px;
//   @media${theme.breakpoints.sm_up}{
//     margin-left: 62px;
//     margin-right: 65px;
//     font-size: 14px;
//   }
// `;

// const CustomDatePickerRes = styled.div`
//   margin-left: 30px;
//   margin-right: 30px;
//   @media ${theme.breakpoints.sm_up} {
//     max-width: 200px;
//     margin-left: 62px;
//     margin-right: 65px;
//   }
// `;
