import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import Drawer from "../Drawer/AgentListDrawerModal";
import { Heading } from "../../../styles/component/style";
import CustomTable from "../CustomTable/CustomTable";
import { makeStyles } from "@material-ui/core";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { numberFormat } from "../../../helpers/functions";

const Index = ({ opendrawerClaim, setOpenDrawerClaim, MTRef, caseDetails }) => {
  return (
    <Drawer
      state={opendrawerClaim}
      setState={setOpenDrawerClaim}
      label="Drawer"
    >
      <Table {...{ setOpenDrawerClaim, MTRef }} data={caseDetails} />
    </Drawer>
  );
};

export default Index;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff",
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c",
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const Table = ({ setOpenDrawerClaim, MTRef, data }) => {
  return (
    <div>
      <Header>
        <Heading style={{ marginBottom: "unset" }}>Claim Items Details</Heading>
        <ImageWrapper>
          <img
            src={require("../../../assets/images/closeModal.svg")}
            onClick={() => setOpenDrawerClaim(false)}
            alt="close"
          />
        </ImageWrapper>
      </Header>
      <AccordionContainer>
        <TableWithAccordion title={"Claim Items"} {...{ MTRef, data }} />
      </AccordionContainer>
    </div>
  );
};

function TableWithAccordion({ MTRef, title, data }) {
  const classes = useStyles();

  const columns = [
    {
      field: "",
      title: "S.No",
      sorting: false,
      render: (rowData) => (
        <p style={{ marginLeft: 35 }}>{rowData?.tableData?.id + 1}</p>
      ),
      headerStyle: {
        padding: `12px 16px 12px 45px`,
      },
      cellStyle: {
        padding: `12px 16px 12px 16px`,
      },
    },
    {
      field: "title",
      title: "Claim Title",
      sorting: false,
      render: (rowData) => <p>{rowData.title}</p>,
    },
    {
      field: "claimValue",
      title: "Claim Value",
      sorting: false,
      render: (rowData) => (
        <StyledTitle style={{ marginLeft: 10 }}>
          {numberFormat(
            parseFloat(rowData?.claimValue).toFixed(2),
            rowData?.unit
          )}
        </StyledTitle>
      ),
    },
  ];

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading style={{ marginLeft: 24 }}>{title}</AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          {data?.caseItems?.length ? (
            <CustomTable
              pluralTitle="Agents"
              singularTitle="Agent"
              {...{
                columns,
                MTRef,
              }}
              state={{
                data: data?.caseItems,
              }}
              data={data?.caseItems}
              noToolbar
              pageSize={1000}
              hidePagination={true}
            />
          ) : (
            <div className="no-result">No Agents Found</div>
          )}
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 19px 40px;
  margin-top: 15px;
  border-bottom: 1px solid ${COLORS.BORDER_GREY};
`;
export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;

export const AccordionContainer = styled.div`
  padding: 0 20px;
`;

export const ImageWrapper = styled.div`
  cursor: pointer;
`;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledTitle = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  @media (min-width: 780px) {
    //width: 200px;
    width: auto;
    min-width: 100px;
  }
`;
