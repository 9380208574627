import React, { useRef } from "react";
import { SnackbarProvider } from "notistack";
import { makeStyles } from "@material-ui/core";
import COLORS from "../assets/Colors";
import UserProvider from "./UserProvider";
import LoaderProvider from "./LoaderProvider";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import CartCountProvider from "./cartCountProvider";
import AlertProvider from "./AlertProvider";
import { SnackbarButton } from "../styles/component/style";
import DialogProvider from "./dialogProvider";
import TranslatorProvider from "./TranslaterProvider";
import NotificationProvider from "./NotifcationProvider";
import SettingsProvider from "./SettingsProvider";
import BundleProvider from "./BundleProvider";
import DictationProvider from "./OrderDictationProvider";
import CalendarProvider from "./CalendarProvider";

const useStyles = makeStyles(() => ({
  success: { backgroundColor: COLORS.BTN_GREEN },
}));

export default function Providers({ children }) {
  const classes = useStyles();
  const snackBarRef = useRef();

  return (
    <SnackbarProvider
      maxSnack={1}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      ref={snackBarRef}
      action={(key) => (
        <SnackbarButton
          onClick={() => snackBarRef?.current?.closeSnackbar(key)}
        >
          Dismiss
        </SnackbarButton>
      )}
      classes={{ variantSuccess: classes.success }}
      autoHideDuration={4000}
    >
      <TranslatorProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SettingsProvider>
            <AlertProvider>
              <DictationProvider>
                <CalendarProvider>
                  <LoaderProvider>
                    <BundleProvider>
                      <DialogProvider>
                        <UserProvider>
                          <NotificationProvider>
                            <CartCountProvider>{children}</CartCountProvider>
                          </NotificationProvider>
                        </UserProvider>
                      </DialogProvider>
                    </BundleProvider>
                  </LoaderProvider>
                </CalendarProvider>
              </DictationProvider>
            </AlertProvider>
          </SettingsProvider>
        </MuiPickersUtilsProvider>
      </TranslatorProvider>
    </SnackbarProvider>
  );
}
