import React from "react";
import ActionBar from "../../common/ActionBar";
import styled from "styled-components";
import { navigate } from "@reach/router";
import labels from "../../../helpers/labels.json";
import PartyTable from "../../common/PartyTable";
import theme from "../../../assets/theme";

const Index = () => {
  const actions = [
    {
      text: labels["actions.createParty"],
      props: {
        onClick: () => {
          navigate("/dashboard/parties/create");
        },
      },
    },
  ];

  return (
    <div>
      <ActionBar actions={actions} />
      <Padding>
        <PartyTable />
      </Padding>
    </div>
  );
};

export default Index;

const Padding = styled.div`
  padding: 30.3px 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 30px;
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
