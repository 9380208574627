import React, { useEffect, useState } from "react";
import {
  CustomCheckbox,
  CustomInputFieldSelect,
} from "../../common/FormInputs";
import CaseService from "../../../services/CaseService";
import { CheckboxContainer } from "../../internal/CreateParty/styles";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { Autocomplete } from "@material-ui/lab";

const MultipleCustomSelect = ({ id, caseIds, setCaseIds }) => {
  const [caseList, setCaseList] = useState([]);
  const [seletAll, setSelectAll] = useState(false);

  useEffect(() => {
    (async function getAgentCases() {
      try {
        const response = await CaseService.getAgentCases(id);
        if (response) {
          // setCaseList(response.caseDet);
          setCaseList([
            { title: "Select all", value: "selectall" },
            ...response.caseDet,
          ]);
          setCaseIds(response.caseDet.filter((item) => item.assigned));
        }
      } catch (error) {
        throw error;
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setCaseIds(newValue);
    setSelectAll(newValue.some((obj) => obj.hasOwnProperty("value")));
  };

  useEffect(() => {
    if (seletAll) {
      setCaseIds(caseList);
    } else {
      setCaseIds([]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seletAll]);

  return (
    <Autocomplete
      multiple
      options={caseList}
      disableCloseOnSelect
      value={caseIds}
      onChange={handleChange}
      getOptionLabel={(option) => (option.title ? option.title : option.id)}
      // ListboxComponent={(props) => (
      //   <List
      //     {...props}
      //     sx={{
      //       maxHeight: "200px",
      //       overflow: "auto",
      //       border: "1px solid #ccc",
      //       backgroundColor: "#ffffff",
      //       borderRadius: "6px",
      //     }}
      //   />
      // )}
      renderOption={(option, { selected }) => (
        <React.Fragment>
          <CheckboxContainer>
            <CustomCheckbox checked={selected} />
            <CheckBoxLabel>{option.title}</CheckBoxLabel>
          </CheckboxContainer>
        </React.Fragment>
      )}
      renderInput={(params) => (
        <CustomInputFieldSelect
          {...params}
          className={"input-white"}
          variant="outlined"
        />
      )}
    />
  );
};

export default MultipleCustomSelect;

const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
`;
