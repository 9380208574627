import React, { useEffect, useState } from "react";
import {
  CustomCheckbox,
  CustomInputFieldSelect,
} from "../../../common/FormInputs";
import CaseService from "../../../../services/CaseService";
import styled from "styled-components";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import "./AssignCase.css";
import { useSnackbar } from "notistack";
import useLoader from "../../../../hooks/useLoader";
import { getErrorMessage } from "../../../../helpers/functions";
import { Autocomplete } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const MultipleCustomSelectModal = ({ modal, setModal, id }) => {
  const [caseList, setCaseList] = useState([]);
  const [caseSelected, setCaseSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [seletAll, setSelectAll] = useState(false);

  useEffect(() => {
    (async function getAgentCases() {
      try {
        setLoading(true);
        const response = await CaseService.agentCases(id, modal.data.id);
        if (response) {
          setCaseList([
            { title: "Select all", value: "selectall" },
            ...response.caseDet,
          ]);
          setCaseSelected(response.caseDet.filter((item) => item.assigned));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    })(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (event, newValue) => {
    setCaseSelected(newValue);
    setSelectAll(newValue.some((obj) => obj.hasOwnProperty("value")));
  };

  useEffect(() => {
    if (seletAll) {
      setCaseSelected(caseList);
    } else {
      setCaseSelected([]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seletAll]);

  const onFormSubmit = async () => {
    try {
      setLoader({ state: true });
      const payload = {
        caseIds: caseSelected
          .map((item) => item.id)
          .filter((item) => item !== undefined),
      };
      const response = await CaseService.assignCasesToAgents(
        modal.data.id,
        payload
      );
      if (response) {
        enqueueSnackbar("Cases assigned successfully", {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setModal({ state: false });
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <Container>
          <HeadingContainer>
            <Heading>Assign case</Heading>
            <CloseModal
              onClick={() => setModal({ state: false })}
              src={require("../../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <AgentFormContainer>
            <Autocomplete
              multiple
              options={caseList}
              disableCloseOnSelect
              value={caseSelected}
              onChange={handleChange}
              loading={loading}
              getOptionLabel={(option) =>
                option.title ? option.title : option.id
              }
              renderOption={(option, { selected }) => (
                <React.Fragment>
                  <CheckboxContainer>
                    <CustomCheckbox checked={selected} />
                    <CheckBoxLabel>{option.title}</CheckBoxLabel>
                  </CheckboxContainer>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <CustomInputFieldSelect
                  {...params}
                  autoFocus={true}
                  className={"input-white"}
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </AgentFormContainer>
          <FormControls>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                onClick={() => setModal({ state: false })}
                style={{
                  padding: "8px 8px",
                  width: "142px",
                  marginRight: "10px",
                }}
              >
                Cancel
              </PrimaryOutlinedCTAButton>
              <PrimaryCTAButton
                onClick={onFormSubmit}
                style={{
                  padding: "8px 8px",
                  width: "142px",
                  marginRight: "10px",
                }}
              >
                Submit
              </PrimaryCTAButton>
            </ButtonContainer>
          </FormControls>
        </Container>
      </div>
    </div>
  );
};

export default MultipleCustomSelectModal;

const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
`;

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 15px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  backface-visibility: hidden;
  max-height: 340px;
  overflow: auto;
  z-index: -1px;
  overflow-x: hidden;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 30px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const FormControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
