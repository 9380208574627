import styled from "styled-components";

export const MessageContainer = styled.div`
  padding: 20px 0px;
  width: 520px;
  text-align: center;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const InputContainer = styled.div`
  padding: 10px 0px;
`;
