import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { navigate } from "@reach/router";

const Index = ({ cards, dashboardCount }) => {
  return (
    <CardContainer>
      {cards.map((card) => (
        <CardWrapper onClick={() => navigate(card.path)} key={card.label}>
          <Value>
            {dashboardCount.hasOwnProperty(card.key)
              ? dashboardCount[card.key]
              : 0}
          </Value>
          <Label>{card.label}</Label>
        </CardWrapper>
      ))}
    </CardContainer>
  );
};

export default Index;

const CardContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 16px 15px 0;
  height: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 33px 35px;
    flex-wrap: nowrap;
    margin: 0;
  }
`;
const CardWrapper = styled.div`
  margin: 16px 6% 16px 0;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 47%;
  border-radius: 11px;
  border: solid 1px ${COLORS.BORDER_GREY};
  background-color: ${COLORS.PRIMARY_WHITE};
  user-select: none;
  cursor: pointer;
  &:nth-child(even) {
    margin-right: 0;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 220px;
    margin: 0 16px;
    &:first-child {
      margin-left: unset;
    }
  }
`;
const Value = styled.h2`
  margin-top: 12px;
  font-size: 30px;
  font-weight: bold;
  color: ${COLORS.BTN_GREEN};
  cursor: pointer;
`;
const Label = styled.p`
  margin-bottom: 13px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontRegular};
`;
