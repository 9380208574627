import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const RuleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 30px;
  font-size: 16px;
  padding-bottom: 40px;
  text-align: justify;
  margin: auto;
  @media ${theme?.breakpoints?.sm_up} {
    width: 70%;
    padding: 0;
    padding-bottom: 40px;
  }
`;
export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 30px;
  margin-bottom: 32px;
  cursor: default;
  padding: 0 20px;
  margin: 20px;
  text-align: center;
  @media (max-width: 1024px) {
    font-size: 24px;
    text-align: center;
  }
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 25px;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 36px;
  }
`;

export const ModelClausePage = styled.div`
  display: flex;
  flex-direction: column;
  text-align: justify;
  width: 70%;
  margin: 40px auto;
`;

export const TextLines = styled.div`
  // padding: 20px;
  line-height: 2.3;
  margin-left: 20px;
`;

export const TextLinesLine = styled.div`
  // padding: 20px;
  // line-height: 2.3;
  margin-left: 20px;
`;

export const TextLinesData = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
  & .first-li-before {
    list-style: "1.1.  ";
    list-style-position: outside !important;
  }
  & .second-li-before {
    list-style: "1.2.  ";
    list-style-position: outside !important;
  }
  & .third-li-before {
    list-style: "1.3.  ";
    list-style-position: outside !important;
  }
  & .four-li-before {
    list-style: "1.4.  ";
    list-style-position: outside !important;
  }
  & .five-li-before {
    list-style: "1.5.  ";
    list-style-position: outside !important;
  }
`;

export const TextLinesDataSeq = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
  & .first-li-before {
    list-style: "2.1.  ";
    list-style-position: outside !important;
  }
  & .second-li-before {
    list-style: "2.2.  ";
    list-style-position: outside !important;
  }
  & .third-li-before {
    list-style: "2.3.  ";
    list-style-position: outside !important;
  }
  & .four-li-before {
    list-style: "2.4.  ";
    list-style-position: outside !important;
  }
  & .five-li-before {
    list-style: "2.5.  ";
    list-style-position: outside !important;
  }
`;

export const TextLinesDataNeg = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
  & .first-li-before {
    list-style: "3.1.  ";
    list-style-position: outside !important;
  }
  & .second-li-before {
    list-style: "3.2.  ";
    list-style-position: outside !important;
  }
  & .third-li-before {
    list-style: "3.3.  ";
    list-style-position: outside !important;
  }
`;

export const TextLinesDataMed = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
  & .first-li-before {
    list-style: "4.1.  ";
    list-style-position: outside !important;
  }
  & .second-li-before {
    list-style: "4.2.  ";
    list-style-position: outside !important;
  }
  & .third-li-before {
    list-style: "4.3.  ";
    list-style-position: outside !important;
  }
  & .four-li-before {
    list-style: "4.4.  ";
    list-style-position: outside !important;
  }
`;

export const TextLinesDataArb = styled.div`
  padding-left: 30px;
  margin-left: 30px;
  text-align: justify;
  & .first-li-before {
    list-style: "5.1.  ";
    list-style-position: outside !important;
  }
  & .second-li-before {
    list-style: "5.2.  ";
    list-style-position: outside !important;
  }
  & .third-li-before {
    list-style: "5.3.  ";
    list-style-position: outside !important;
  }
  & .four-li-before {
    list-style: "5.4.  ";
    list-style-position: outside !important;
  }
  & .five-li-before {
    list-style: "5.5.  ";
    list-style-position: outside !important;
  }
`;
