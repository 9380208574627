import React, { Fragment } from "react";
import { FormControl, Block, HalfWidth, Margin } from "../styles";
import FormField from "../../../common/FormField/FormField";
import { states } from "../../../../helpers/constants";
import labels from "../../../../helpers/labels.json";

export default function AddressFormFields({
  type = "comm",
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) {
  return (
    <Fragment>
      <FormControl>
        <FormField
          white
          disabled={values.billingAddressType === "same" ? true : false}
          input={{
            type: "text",
            name: `${type}_billing_house_no`,
            label: labels["user.address"],
            required: true,
          }}
          {...{
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }}
        />
      </FormControl>
      <FormControl>
        <Block style={{ margin: 0 }}>
          <HalfWidth>
            <FormField
              white
              disabled={values.billingAddressType === "same" ? true : false}
              input={{
                type: "text",
                name: `${type}_billing_locality`,
                label: labels["user.locality"],
                required: true,
              }}
              {...{
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
              }}
            />
          </HalfWidth>
          <HalfWidth>
            <FormField
              white
              disabled={values.billingAddressType === "same" ? true : false}
              input={{
                type: "text",
                name: `${type}_billing_city`,
                label: labels["user.city"],
                required: true,
              }}
              {...{
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
              }}
            />
          </HalfWidth>
        </Block>
      </FormControl>
      <FormControl>
        <Block style={{ margin: 0 }}>
          <HalfWidth>
            <FormField
              white
              disabled={values.billingAddressType === "same" ? true : false}
              menuItems={states}
              input={{
                type: "select",
                name: `${type}_billing_state`,
                label: labels["user.state"],
                required: true,
              }}
              {...{
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
              }}
            />
          </HalfWidth>

          <HalfWidth className="disabledArrows">
            <Margin>
              <FormField
                white
                disabled={values.billingAddressType === "same" ? true : false}
                input={{
                  type: "number",
                  name: `${type}_billing_pin`,
                  label: labels["user.pin"],
                  required: true,
                }}
                {...{
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                }}
              />
            </Margin>
          </HalfWidth>
        </Block>
      </FormControl>
      <FormControl>
        <FormField
          white
          disabled={values.billingAddressType === "same" ? true : false}
          input={{
            type: "text",
            name: `${type}_billing_landmark`,
            label: labels["user.landmark"],
            required: true,
          }}
          {...{
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }}
        />
      </FormControl>
    </Fragment>
  );
}
