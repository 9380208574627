import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import VideoPlayer from "../Video";

const Index = ({ styledBtn, boxSize, label = "Watch Tutorial", src }) => {
  return (
    <VideoWrapper style={boxSize}>
      {src && <VideoPlayer src={src} />}
    </VideoWrapper>
  );
};

export default Index;

export const VideoWrapper = styled.div`
  background: ${COLORS.PRIMARY_WHITE};
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${theme?.breakpoints?.lg_up} {
    justify-content: flex-start;
  }
`;
