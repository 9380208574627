import React, { useState, useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { ButtonContainer, Label } from "../styles";
import { PrimaryCTAButton } from "../../../common/Buttons";
import SearchBar from "../../../common/CustomTable/common/SearchBar";
import CustomChip from "../../../common/Chip";
import { CustomCheckbox, CustomRadioGroup } from "../../../common/FormInputs";
import queryString from "query-string";
import { CircularProgress } from "@material-ui/core";
import PartyService from "../../../../services/PartyService";
import useDebounce from "../../../../hooks/useDebounce";
import _ from "lodash";

export default function FilterModal({
  setModal,
  advanceFilter,
  setAdvanceFilter,
  partyRoles = [],
}) {
  const [partyList, setPartyList] = useState([]);
  const [selectPartyList, setSelectedPartyList] = useState(
    advanceFilter?.parties.length > 0 ? advanceFilter.parties : []
  );
  const [role, setRole] = useState(advanceFilter.role);
  const [loading, setLoading] = useState(false);
  const [inputText, setInputText] = useState("");
  const debounceValue = useDebounce(inputText, 500);

  useEffect(() => {
    if (debounceValue) {
      partySearch(debounceValue);
    } else {
      setPartyList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    if (role?.value) {
      setInputText("");
    }
  }, [role]);

  async function partySearch(inputText) {
    setLoading(true);
    let response = {};
    try {
      let params = {
        page: 1,
        perPage: 100,
      };
      if (role?.value === "claimant") {
        params["claimantPartySearch"] = inputText;
      } else {
        params["respondentPartySearch"] = inputText;
      }
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      response = await PartyService.getCaseParties(stringParams);
      setPartyList(response.total ? response.data : []);
    } catch (error) {
      return error;
    } finally {
      setLoading(false);
    }
  }

  const partyCheckBoxChange = (checked, party) => {
    if (checked) {
      addParty(party);
    } else {
      removeParty(party);
    }
  };

  const addParty = (party) => {
    const partyList = [...selectPartyList];
    partyList.push(party);
    setSelectedPartyList(partyList);
  };

  const removeParty = (party) => {
    let partyList = [...selectPartyList];
    partyList = partyList.filter((el) => el.id !== party.id);
    setSelectedPartyList(partyList);
    setAdvanceFilter({
      parties: partyList,
      role
    });
  };

  const ResultFilter = selectPartyList => {
    setAdvanceFilter({
      parties: [],
      role: { label: "Claimant", value: "claimant" }
    });
  };

  return (
    <DrawerContainer role="presentation">
      <HeadingContainer>
        <Heading>
          Filters{" "}
          <span
            onClick={() => {
              ResultFilter(selectPartyList);
              setInputText("");
              setSelectedPartyList([]);
              setRole(partyRoles[0]);
              setModal(false);
            }}
          >
            Remove All filters
          </span>
        </Heading>
        <CloseModal
          onClick={() => setModal(false)}
          src={require("../../../../assets/images/closeModal.svg")}
        />
      </HeadingContainer>

      <AgentFormContainer>
        <div>
          <Label>Party Role</Label>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <CheckboxContainer className="party-checkbox">
              <CustomRadioGroup
                handleChange={(el) => {
                  const role = partyRoles.find(
                    (e) => e.value === el.target.value
                  );
                  setRole(role);
                }}
                name={"value"}
                radioInputs={partyRoles}
                values={role}
              />
            </CheckboxContainer>
          </div>
        </div>
        <Label>Party</Label>
        <InputBox>
          <SearchBar
            name={"partysearch"}
            placeHolderText="Search Parties"
            backgroundColor="#ffffff"
            {...{ inputText, setInputText }}
            width="100%"
          />
          <ChipContainer>
            {selectPartyList?.map((party, index) => (
              <CustomChip key={index} remove={() => removeParty(party)}>
                {party?.name}
              </CustomChip>
            ))}
            {selectPartyList?.length === 0 && (
              <div className="no-record">No Parties Selected</div>
            )}
          </ChipContainer>
          <Seperator />
          <div className="party-listing">
            {!loading ? (
              <>
                {inputText &&
                  partyList.map((party, index) => (
                    <CheckboxContainer key={index}>
                      <CustomCheckbox
                        checked={selectPartyList.some(
                          (el) => el.id === party.id
                        )}
                        name={party?.name + index}
                        onChange={(ev) =>
                          partyCheckBoxChange(ev?.target?.checked, party)
                        }
                      />
                      <span>{party?.name}</span>
                    </CheckboxContainer>
                  ))}
                {inputText && partyList.length === 0 ? (
                  <div className="no-record">No Parties found</div>
                ) : (
                  <div className="no-record">
                    {!inputText && "Type to Search Parties"}
                  </div>
                )}
              </>
            ) : (
              <div className="loader">
                <CircularProgress />
              </div>
            )}
          </div>
        </InputBox>
        <ButtonContainer>
          <PrimaryCTAButton
            onClick={() => {
              setModal(false);
              setAdvanceFilter({
                parties: selectPartyList,
                role,
              });
            }}
            style={{ width: 450 }}
          >
            Apply Filters
          </PrimaryCTAButton>
        </ButtonContainer>
      </AgentFormContainer>
    </DrawerContainer>
  );
}

const DrawerContainer = styled.div`
  width: 100vw;
  @media ${theme?.breakpoints?.sm_up} {
    width: 618px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputBox = styled.div`
  border-radius: 5px;
  background-color: #f9f9f9;
  max-width: 450px;
  padding: 9px;
  margin-top: 12px;
  margin-bottom: 14px;
  & .party-listing {
    height: 200px;
    overflow: auto;
  }
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .no-record {
    text-align: center;
    opacity: 0.6;
    padding: 10px;
    width: 100%;
  }
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const ChipContainer = styled.div`
  display: flex;
  flex-flow: wrap;
  max-height: 130px;
  overflow: auto;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
  span {
    font-family: ${theme.fonts.primaryFontSemiBold};
    text-decoration: underline;
    margin-left: 23px;
    font-size: 12px;
    color: ${COLORS.BTN_GREEN};
    user-select: none;
    cursor: pointer;
  }
`;

const AgentFormContainer = styled.div`
  padding-top: 14px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  & form {
    padding-top: 10px;
  }
`;

const Seperator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.INPUT_BORDER};
  margin-top: 13px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 0px;
  &.party-checkbox {
    padding: 20px 0px;
  }
  span {
    color: ${COLORS.COLOR_DARK};
    font-family: ${theme.fonts.primaryFontRegular};
  }
`;
