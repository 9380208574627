import React, { Fragment } from "react";
import { FormControl, Block, HalfWidth, Margin } from "../styles";
import FormField from "../../../common/FormField/FormField";
import { states } from "../../../../helpers/constants";
import labels from "../../../../helpers/labels.json";

export default function AddressFormFields({
  type = "comm",
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
}) {
  return (
    <Fragment>
      <FormControl>
        <FormField
          white
          input={{
            type: "text",
            name: `${type}_house_no`,
            label: labels["user.house_label"],
            required: true,
          }}
          handleChange={(e) => {
            if (values.billingAddressType === "same") {
              setFieldValue(`${type}_house_no`, e?.target?.value);
              setFieldValue(`${type}_billing_house_no`, e?.target?.value);
            } else if (values.billingAddressType === "new") {
              setFieldValue(`${type}_house_no`, e?.target?.value);
            }
          }}
          {...{
            values,
            errors,
            touched,
            handleBlur,
          }}
        />
      </FormControl>
      <FormControl>
        <Block style={{ margin: 0 }}>
          <HalfWidth>
            <FormField
              white
              input={{
                type: "text",
                name: `${type}_locality`,
                label: labels["user.locality"],
                required: true,
              }}
              handleChange={(e) => {
                if (values.billingAddressType === "same") {
                  setFieldValue(`${type}_locality`, e?.target?.value);
                  setFieldValue(`${type}_billing_locality`, e?.target?.value);
                } else if (values.billingAddressType === "new") {
                  setFieldValue(`${type}_locality`, e?.target?.value);
                }
              }}
              {...{
                values,
                errors,
                touched,
                handleBlur,
              }}
            />
          </HalfWidth>
          <HalfWidth>
            <FormField
              white
              input={{
                type: "text",
                name: `${type}_city`,
                label: labels["user.city"],
                required: true,
              }}
              handleChange={(e) => {
                if (values.billingAddressType === "same") {
                  setFieldValue(`${type}_city`, e?.target?.value);
                  setFieldValue(`${type}_billing_city`, e?.target?.value);
                } else if (values.billingAddressType === "new") {
                  setFieldValue(`${type}_city`, e?.target?.value);
                }
              }}
              {...{
                values,
                errors,
                touched,
                handleBlur,
              }}
            />
          </HalfWidth>
        </Block>
      </FormControl>
      <FormControl>
        <Block style={{ margin: 0 }}>
          <HalfWidth>
            <FormField
              white
              menuItems={states}
              input={{
                type: "select",
                name: `${type}_state`,
                label: labels["user.state"],
                required: true,
              }}
              handleChange={(e) => {
                if (values.billingAddressType === "same") {
                  setFieldValue(`${type}_state`, e?.target?.value);
                  setFieldValue(`${type}_billing_state`, e?.target?.value);
                } else if (values.billingAddressType === "new") {
                  setFieldValue(`${type}_state`, e?.target?.value);
                }
              }}
              {...{
                values,
                errors,
                touched,
                handleBlur,
              }}
            />
          </HalfWidth>

          <HalfWidth className="disabledArrows">
            <Margin>
              <FormField
                white
                input={{
                  type: "number",
                  name: `${type}_pin`,
                  label: labels["user.pin"],
                  required: true,
                }}
                handleChange={(e) => {
                  if (values.billingAddressType === "same") {
                    setFieldValue(`${type}_pin`, e?.target?.value);
                    setFieldValue(`${type}_billing_pin`, e?.target?.value);
                  } else if (values.billingAddressType === "new") {
                    setFieldValue(`${type}_pin`, e?.target?.value);
                  }
                }}
                {...{
                  values,
                  errors,
                  touched,
                  handleBlur,
                }}
              />
            </Margin>
          </HalfWidth>
        </Block>
      </FormControl>
      <FormControl>
        <FormField
          white
          input={{
            type: "text",
            name: `${type}_landmark`,
            label: labels["user.landmark"],
            required: true,
          }}
          handleChange={(e) => {
            if (values.billingAddressType === "same") {
              setFieldValue(`${type}_landmark`, e?.target?.value);
              setFieldValue(`${type}_billing_landmark`, e?.target?.value);
            } else if (values.billingAddressType === "new") {
              setFieldValue(`${type}_landmark`, e?.target?.value);
            }
          }}
          {...{
            values,
            errors,
            touched,
            handleBlur,
          }}
        />
      </FormControl>
    </Fragment>
  );
}
