import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const Container = styled.div`
  padding: 34px 15px;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 43px;
  }
`;

export const TableWrapper = styled.div``;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: default;
`;
export const StyledTitle = styled.div`
  @media (min-width: 780px) {
    font-family: ${theme.fonts.primaryFontBold};
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
  margin: 0 auto;
  @media (min-width) {
    justify-content: space-between;
  }
`;
export const AcceptButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-left: 4px;
  margin-right: 20px;
  cursor: pointer;
`;
export const DeclineButton = styled.button`
  border: none;
  background: none;
  outline: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const Padding = styled.div`
  padding-left: 29px;
  @media (min-width: 780px) {
    padding-left: 0;
  }
`;
