import API from "../api/manager";
import config from "../api/config";

const getPendingAction = (query) => {
  return API({
    method: "GET",
    url: config.urls.dashboard.pendingAction + query,
  });
};

export default {
  getPendingAction,
};
