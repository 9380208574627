import React from "react";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";
import { Button, StyledFormElementsWrapper } from "../Styles";

const CaseActionCTA = ({
  isBigScreen,
  onClick,
  handleSubmit,
  PrimaryOutlinedCTA,
  PrimaryCTA,
  editFlag,
}) => {
  return (
    <>
      <StyledFormElementsWrapper
        style={{
          display: "flex",
          justifyContent: isBigScreen ? "space-between" : "flex-start",
        }}
      >
        <Button>
          <PrimaryOutlinedCTAButton
            style={{
              marginLeft: "unset",
            }}
            onClick={onClick}
          >
            {PrimaryOutlinedCTA
              ? PrimaryOutlinedCTA
              : editFlag
              ? "Back"
              : "Save As Draft"}
          </PrimaryOutlinedCTAButton>
        </Button>
        <Button
          style={{
            marginLeft: "22px",
          }}
        >
          <PrimaryCTAButton onClick={handleSubmit}>
            {PrimaryCTA ? PrimaryCTA : editFlag ? "Next" : "Proceed"}
          </PrimaryCTAButton>
        </Button>
      </StyledFormElementsWrapper>
    </>
  );
};

export default CaseActionCTA;
