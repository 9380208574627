import React, { useState, useEffect } from "react";
import Drawer from "@material-ui/core/Drawer";
import styled from "styled-components";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { BasicDetailsLabel, Row } from "../Styles";
import { CustomInputField } from "../../../common/FormInputs";
import {
  PrimaryCTAButton,
  PrimaryOutlinedCTAButton,
} from "../../../common/Buttons";

export default function DescriptionModal({
  modal = { state: false },
  setModal,
  values,
  setFieldValue,
  readOnly,
}) {
  const [text, setText] = useState("");

  useEffect(() => {
    if (!modal.state) {
      setText("");
    } else {
      const newText = readOnly
        ? modal.des
        : values.claim_items[modal.index].description;
      if (newText) {
        setText(newText);
      }
    }
  }, [readOnly, modal.des, modal.state, modal.index, values]);
  return (
    <Drawer anchor={"right"} open={modal.state}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Claim Item Description</Heading>
          <CloseModal
            onClick={() => setModal({ state: false })}
            src={require("../../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <BasicDetailsLabel>Description</BasicDetailsLabel>
          {modal.state && (
            <CustomInputField
              variant="outlined"
              className={"input-height-auto"}
              style={{ marginTop: 6 }}
              multiline={true}
              rows={5}
              maxRows={5}
              name={modal.name}
              value={text}
              disabled={modal?.editable ? false : readOnly}
              onChange={(e) => setText(e.target.value)}
            />
          )}

          <Row>
            <PrimaryOutlinedCTAButton
              onClick={() => setModal({ state: false })}
              style={{ width: "47%" }}
            >
              Back
            </PrimaryOutlinedCTAButton>
            {(!readOnly || modal?.editable) && (
              <PrimaryCTAButton
                onClick={() => {
                  setFieldValue(modal.name, text);
                  setModal({ state: false });
                }}
                style={{ width: "47%" }}
              >
                Save
              </PrimaryCTAButton>
            )}
          </Row>
        </AgentFormContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 100vw;
  @media ${theme?.breakpoints?.sm_up} {
    width: 555px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 35px;
  padding-bottom: 20px;
  padding-left: 30px;
  padding-right: 54px;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.div`
  padding-top: 26px;
  padding-left: 38px;
  padding-right: 38px;
  padding-bottom: 26px;
  height: 100%;
  & form {
    padding-top: 10px;
  }
`;
