import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { makeStyles } from "@material-ui/core";
import React from "react";

export const OuterContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  margin-top: 20px;
  padding-bottom: 32px;
  padding-left: 13px;
  padding-right: 11px;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 43px;
    margin-top: 0;
  }
  & .delete {
    display: flex;
    cursor: pointer;
  }
`;
export const BackArrow = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14.5px;
  cursor: pointer;
`;

export const Heading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
  width: 100%;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;
export const Subheading = styled.div`
  max-width: 576px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  line-height: 1.29;
  margin-top: 20px;
  margin-bottom: 40px;
`;
export const FormContainer = styled.form`
  margin-top: 20px;

  & .header-container {
    display: flex;
    width: 100%;
    @media ${theme?.breakpoints?.sm_up} {
      width: 436px;
    }
    align-items: center;
    & .removeIcon {
      cursor: pointer;
      margin-top: 10px;
    }
  }
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  text-transform: capitalize;
  color: ${COLORS.INPUT_LABEL};
  margin-right: 8px;
`;

export function StyledLabel({ children, required, ...props }) {
  return (
    <FormLabel
      {...props}
      style={{
        fontFamily: theme.fonts.primaryFontSemiBold,
        fontSize: 10,
        color: COLORS.INPUT_LABEL,
      }}
    >
      {children}
      {required && <span className="required-star"> *</span>}
    </FormLabel>
  );
}

export const useStyles = makeStyles(() => ({
  root: {},
}));

export const SampleText = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  margin-top: 10px;
  span {
    text-decoration: underline;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
export const Pad = styled.div`
  width: 20px;
  opacity: 0;
`;

export const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: inherit;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxFormControl = styled.div`
  margin-top: 15px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

export const HalfWidth = styled.div`
  width: 47%;
`;

export const TextCenter = styled.p`
  width: 436px;
  display: flex;
  align-items: center;
  justify-content: center;
  & h2 {
    margin-bottom: 10px;
  }
`;
export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.83;
  margin-bottom: 17px;
`;
export const GreenLink = styled.button`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.BTN_GREEN};
  font-size: 14px;
  font-weight: bold;
  border: none;
  background: none;
  outline: none;
`;

export const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
`;
