import PartyService from "../../../../services/PartyService";
import { getErrorMessage } from "../../../../helpers/functions";
import { navigate } from "@reach/router";

export async function createParty(
  payload,
  enqueueSnackbar,
  draftId = null,
  setLoader = () => { }
) {
  try {
    setLoader({ state: true, message: "Creating Party" });
    let response = null;
    if (draftId) {
      response = await PartyService.submitPartyDraft(payload, draftId);
    } else {
      response = await PartyService.createParty(payload);
    }
    if (response) {
      if (response?.message) {
        enqueueSnackbar(response.message, {
          variant: "success"
        });
        navigate("/dashboard/parties");
      } else {
        navigate("/dashboard/parties");
      }
    }
  } catch (error) {
    const message = getErrorMessage(error);
    enqueueSnackbar(message, {
      variant: "error"
    });
  } finally {
    setLoader({ state: false });
  }
}

export async function createCaseParty(
  payload,
  enqueueSnackbar,
  draftId = null,
  resolutionKind,
  setLoader = () => { }
) {
  try {
    setLoader({ state: true, message: "Creating Party" });
    let response = null;
    if (draftId) {
      response = await PartyService.submitPartyDraft(payload, draftId);
    } else {
      response = await PartyService.createParty(payload);
    }
    if (response) {
      enqueueSnackbar(response.message, {
        variant: "success"
      });
      navigate(`/dashboard/cases/create?caseType=${resolutionKind}`);
    }
  } catch (error) {
    const message = getErrorMessage(error);
    enqueueSnackbar(message, {
      variant: "error"
    });
  } finally {
    setLoader({ state: false });
  }
}

export async function draftParty(
  payload,
  enqueueSnackbar,
  draftId = null,
  setLoader = () => { }
) {
  try {
    setLoader({ state: true, message: "Saving Draft..." });
    let response = null;
    if (draftId) {
      response = await PartyService.updatePartyDraft(payload, draftId);
    } else {
      response = await PartyService.createPartyDraft(payload);
    }
    if (response) {
      enqueueSnackbar(response.message, {
        variant: "success"
      });
      navigate("/dashboard/parties");
    }
  } catch (error) {
    const message = getErrorMessage(error);
    enqueueSnackbar(message, {
      variant: "error"
    });
  } finally {
    setLoader({ state: false });
  }
}

export async function getPartyOrDraft(
  id,
  setter = () => { },
  enqueueSnackbar = () => { },
  setLoader = () => { }
) {
  try {
    setLoader({ state: true, message: "Fetching Party details..." });
    const response = await PartyService.getPartyOrDraft(id);
    if (response) {
      setter(response);
    }
  } catch (error) {
    const message = getErrorMessage(error);
    enqueueSnackbar(message, {
      variant: "error"
    });
  } finally {
    setLoader({ state: false });
  }
}
