import React, { useState, useEffect } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryOutlinedCTAButton } from "../../common/Buttons";
import { Modal, CircularProgress } from "@material-ui/core";
import CaseService from "../../../services/CaseService";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";

export default function FilterModal({ setModal, modal }) {
  const [caseList, setCaseList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (modal.state) {
      (async function agentCases() {
        try {
          setLoading(true);
          const response = await CaseService.agentCases(
            modal.data.partyId,
            modal.data.id
          );
          if (response) {
            setCaseList(response.caseDet.filter((item) => item.assigned));
          }
        } catch (error) {
          const message = getErrorMessage(error);
          enqueueSnackbar(message, {
            variant: "error",
          });
        } finally {
          setLoading(false);
        }
      })();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal?.state]);

  return (
    <Modal open={modal.state} onClose={() => setModal({ state: false })}>
      <DrawerContainer role="presentation">
        <Container>
          <HeadingContainer>
            <Heading>Assigned cases</Heading>
            <CloseModal
              onClick={() => setModal({ state: false })}
              src={require("../../../assets/images/closeModal.svg")}
            />
          </HeadingContainer>
          <AgentFormContainer>
            <InputBox>
              {loading ? (
                <div className="loader">
                  <CircularProgress />
                </div>
              ) : (
                caseList.map((item) => (
                  <ul>
                    <li>
                      <Label className="label">{item.title}</Label>
                    </li>
                  </ul>
                ))
              )}
            </InputBox>
          </AgentFormContainer>
          <FormControls>
            <ButtonContainer>
              <PrimaryOutlinedCTAButton
                onClick={() => setModal({ state: false })}
                style={{
                  padding: "8px 8px",
                  width: "142px",
                  marginRight: "10px",
                }}
              >
                Close
              </PrimaryOutlinedCTAButton>
            </ButtonContainer>
          </FormControls>
        </Container>
      </DrawerContainer>
    </Modal>
  );
}

const Container = styled.div`
  margin: 0 15px;
  background-color: white;
  outline: none;
  border-radius: 8px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 426px;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding: 24px 15px 30px 35px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  backface-visibility: hidden;
  max-height: 500px;
  overflow: auto;
  z-index: -1px;
  overflow-x: hidden;
  @media ${theme?.breakpoints?.sm_up} {
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 60px;
    padding-bottom: 30px;
  }
`;

const InputBox = styled.div`
  border-radius: 5px;
  background-color: #f9f9f9;
  max-width: 450px;
  & .party-listing {
    height: 250px;
    overflow: auto;
  }
  & .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  & .no-record {
    text-align: center;
    opacity: 0.6;
    padding: 10px;
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  margin-bottom: 15px;
`;

const FormControls = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: #293461;
  @media ${theme?.breakpoints?.lg_up} {
    font-size: 14px;
  }
`;
