import React from "react";
import Accordion from "@material-ui/core/ExpansionPanel";
import AccordionSummary from "@material-ui/core/ExpansionPanelSummary";
import AccordionDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import labels from "../../../helpers/labels.json";
import { Formik } from "formik";
import { PrimaryCTAButton } from "../../common/Buttons";
import FormField from "../../common/FormField/index";
import CaseService from "../../../services/CaseService";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import { getErrorMessage } from "../../../helpers/functions";

const inputs = [
  {
    type: "text",
    name: "companyLaw",
    label: labels["bulkupload.companyLaw"]
    // required: true
  },
  {
    type: "text",
    name: "claimantFieldofBusiness",
    label: labels["bulkupload.claimantFieldofBusiness"]
  },
  {
    type: "text",
    name: "dateofAgreement",
    label: labels["bulkupload.dateofAgreement"]
  },
  {
    type: "text",
    name: "purposeofAgreement",
    label: labels["bulkupload.purposeofAgreement"]
  },
  {
    type: "text",
    name: "dateofArbitrationNotice",
    label: labels["bulkupload.dateofArbitrationNotice"]
  },
  {
    type: "text",
    name: "dateofIntimation",
    label: labels["bulkupload.dateofIntimation"]
  },
  {
    type: "text",
    name: "dateofResponse",
    label: labels["bulkupload.dateofResponse"]
  },
  {
    type: "text",
    name: "claimPrayer1",
    label: labels["bulkupload.claimPrayer1"]
  },
  {
    type: "text",
    name: "claimPrayer2",
    label: labels["bulkupload.claimPrayer2"]
  },
  {
    type: "text",
    name: "claimPrayer3",
    label: labels["bulkupload.claimPrayer3"]
  },
  {
    type: "text",
    name: "averment",
    label: labels["bulkupload.averment"]
  },
  {
    type: "text",
    name: "submission1",
    label: labels["bulkupload.submission1"]
  },
  {
    type: "text",
    name: "submission2",
    label: labels["bulkupload.submission2"]
  },
  {
    type: "text",
    name: "submission3",
    label: labels["bulkupload.submission3"]
  },
  {
    type: "text",
    name: "submission4",
    label: labels["bulkupload.submission4"]
  },
  {
    type: "text",
    name: "submission5",
    label: labels["bulkupload.submission5"]
  },
  {
    type: "text",
    name: "submission6",
    label: labels["bulkupload.submission6"]
  },
  {
    type: "text",
    name: "anyOtherAverment",
    label: labels["bulkupload.anyOtherAverment"]
  },
  {
    type: "text",
    name: "defencePrayer1",
    label: labels["bulkupload.defencePrayer1"]
  },
  {
    type: "text",
    name: "defencePrayer2",
    label: labels["bulkupload.defencePrayer2"]
  },
  {
    type: "text",
    name: "defencePrayer3",
    label: labels["bulkupload.defencePrayer3"]
  },
  {
    type: "text",
    name: "claimantTypeDescription",
    label: labels["bulkupload.claimantTypeDescription"]
  },
  {
    type: "text",
    name: "nameofAgreement",
    label: labels["bulkupload.nameofAgreement"]
  },
  {
    type: "text",
    name: "referenceofClause",
    label: labels["bulkupload.referenceofClause"]
  },
  {
    type: "text",
    name: "noticeofArbPrayer1",
    label: labels["bulkupload.noticeofArbPrayer1"]
  },
  {
    type: "text",
    name: "noticeofArbPrayer2",
    label: labels["bulkupload.noticeofArbPrayer2"]
  },
  {
    type: "text",
    name: "interimReliefSought",
    label: labels["bulkupload.interimReliefSought"]
  },
  {
    type: "text",
    name: "interimRelief1",
    label: labels["bulkupload.interimRelief1"]
  },
  {
    type: "text",
    name: "interimRelief2",
    label: labels["bulkupload.interimRelief2"]
  },
  {
    type: "text",
    name: "interimRelief3",
    label: labels["bulkupload.interimRelief3"]
  },
  {
    type: "text",
    name: "dateofAffidavit",
    label: labels["bulkupload.dateofAffidavit"]
  },
  {
    type: "text",
    name: "dateofAward",
    label: labels["bulkupload.dateofAward"]
  },
  {
    type: "text",
    name: "content",
    label: labels["bulkupload.content"]
  },
  {
    type: "text",
    name: "placeofAward",
    label: labels["bulkupload.placeofAward"]
  }
];

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "#f9f9f9",
    border: "solid 0.5px #e1e3ee",
    boxShadow: "none",
    margin: "16px 0px 0px !important",
    "& .MuiIconButton-root": {
      padding: 0,
      marginRight: 15,
      backgroundColor: "#fff"
    },
    "& .MuiSvgIcon-root": {
      fill: "#00838c"
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  }
}));

function BulkUpload({ id, setTriggerCaseCall, title, caseDetails }) {
  const classes = useStyles();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  async function onFormSubmit(values) {
    const {
      companyLaw,
      claimantFieldofBusiness,
      dateofAgreement,
      purposeofAgreement,
      dateofArbitrationNotice,
      dateofIntimation,
      dateofResponse,
      claimPrayer1,
      claimPrayer2,
      claimPrayer3,
      averment,
      submission1,
      submission2,
      submission3,
      submission4,
      submission5,
      submission6,
      anyOtherAverment,
      defencePrayer1,
      defencePrayer2,
      defencePrayer3,
      claimantTypeDescription,
      nameofAgreement,
      referenceofClause,
      noticeofArbPrayer1,
      noticeofArbPrayer2,
      interimReliefSought,
      interimRelief1,
      interimRelief2,
      interimRelief3,
      dateofAffidavit,
      dateofAward,
      content,
      placeofAward
    } = values;
    try {
      setLoader({ state: true, message: "Update bulkuplod..." });
      const arbitrationAgreementFields = {
        companyLaw,
        claimantFieldofBusiness,
        dateofAgreement,
        purposeofAgreement,
        dateofArbitrationNotice,
        dateofIntimation,
        dateofResponse,
        claimPrayer1,
        claimPrayer2,
        claimPrayer3,
        averment,
        submission1,
        submission2,
        submission3,
        submission4,
        submission5,
        submission6,
        anyOtherAverment,
        defencePrayer1,
        defencePrayer2,
        defencePrayer3,
        claimantTypeDescription,
        nameofAgreement,
        referenceofClause,
        noticeofArbPrayer1,
        noticeofArbPrayer2,
        interimReliefSought,
        interimRelief1,
        interimRelief2,
        interimRelief3,
        dateofAffidavit,
        dateofAward,
        content,
        placeofAward
      };
      const response = await CaseService.arbAgreementDetails(id, {
        arbitrationAgreementFields
      });
      if (response) {
        enqueueSnackbar(response.message, {
          variant: "success"
        });
        setTriggerCaseCall(true);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error"
      });
    } finally {
      setLoader({ state: false });
    }
  }

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionHeading style={{ marginLeft: 24 }}>{title}</AccordionHeading>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Formik
            initialValues={{
              companyLaw:
                caseDetails?.arbitrationAgreementFields?.companyLaw || "",
              claimantFieldofBusiness:
                caseDetails?.arbitrationAgreementFields
                  ?.claimantFieldofBusiness || "",
              dateofAgreement:
                caseDetails?.arbitrationAgreementFields?.dateofAgreement || "",
              purposeofAgreement:
                caseDetails?.arbitrationAgreementFields?.purposeofAgreement ||
                "",
              dateofArbitrationNotice:
                caseDetails?.arbitrationAgreementFields
                  ?.dateofArbitrationNotice || "",
              dateofIntimation:
                caseDetails?.arbitrationAgreementFields?.dateofIntimation || "",
              dateofResponse:
                caseDetails?.arbitrationAgreementFields?.dateofResponse || "",
              claimPrayer1:
                caseDetails?.arbitrationAgreementFields?.claimPrayer1 || "",
              claimPrayer2:
                caseDetails?.arbitrationAgreementFields?.claimPrayer2 || "",
              claimPrayer3:
                caseDetails?.arbitrationAgreementFields?.claimPrayer3 || "",
              averment: caseDetails?.arbitrationAgreementFields?.averment || "",
              submission1:
                caseDetails?.arbitrationAgreementFields?.submission1 || "",
              submission2:
                caseDetails?.arbitrationAgreementFields?.submission2 || "",
              submission3:
                caseDetails?.arbitrationAgreementFields?.submission3 || "",
              submission4:
                caseDetails?.arbitrationAgreementFields?.submission4 || "",
              submission5:
                caseDetails?.arbitrationAgreementFields?.submission5 || "",
              submission6:
                caseDetails?.arbitrationAgreementFields?.submission6 || "",
              anyOtherAverment:
                caseDetails?.arbitrationAgreementFields?.anyOtherAverment || "",
              defencePrayer1:
                caseDetails?.arbitrationAgreementFields?.defencePrayer1 || "",
              defencePrayer2:
                caseDetails?.arbitrationAgreementFields?.defencePrayer2 || "",
              defencePrayer3:
                caseDetails?.arbitrationAgreementFields?.defencePrayer3 || "",
              claimantTypeDescription:
                caseDetails?.arbitrationAgreementFields
                  ?.claimantTypeDescription || "",
              nameofAgreement:
                caseDetails?.arbitrationAgreementFields?.nameofAgreement || "",
              referenceofClause:
                caseDetails?.arbitrationAgreementFields?.referenceofClause ||
                "",
              noticeofArbPrayer1:
                caseDetails?.arbitrationAgreementFields?.noticeofArbPrayer1 ||
                "",
              noticeofArbPrayer2:
                caseDetails?.arbitrationAgreementFields?.noticeofArbPrayer2 ||
                "",
              interimReliefSought:
                caseDetails?.arbitrationAgreementFields?.interimReliefSought ||
                "",
              interimRelief1:
                caseDetails?.arbitrationAgreementFields?.interimRelief1 || "",
              interimRelief2:
                caseDetails?.arbitrationAgreementFields?.interimRelief2 || "",
              interimRelief3:
                caseDetails?.arbitrationAgreementFields?.interimRelief3 || "",
              dateofAffidavit:
                caseDetails?.arbitrationAgreementFields?.dateofAffidavit || "",
              dateofAward:
                caseDetails?.arbitrationAgreementFields?.dateofAward || "",
              content: caseDetails?.arbitrationAgreementFields?.content || "",
              placeofAward:
                caseDetails?.arbitrationAgreementFields?.placeofAward || ""
            }}
            enableReinitialize
            validateOnBlur
            // validationSchema={AddAgentValidationSchema}
            validateOnChange
            onSubmit={onFormSubmit}
          >
            {({
              values,
              handleChange,
              errors,
              handleSubmit,
              touched,
              setFieldValue,
              setFieldError,
              handleBlur
            }) => (
              <form onSubmit={handleSubmit}>
                {inputs.map((input, index) => (
                  <FormField
                    key={index}
                    {...{
                      input,
                      values,
                      handleChange,
                      errors,
                      handleSubmit,
                      touched,
                      setFieldValue,
                      setFieldError,
                      handleBlur
                    }}
                  />
                ))}
                <PrimaryCTAButton
                  style={{ width: "250px", marginTop: 16 }}
                  onClick={handleSubmit}
                >
                  {labels["buttons.save"]}
                </PrimaryCTAButton>
              </form>
            )}
          </Formik>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
}

export default BulkUpload;

export const TableContainer = styled.div`
  /* margin: 30px 29px auto 38px; */
  width: 100%;
  & .no-result {
    font-family: ${theme.fonts.primaryFontSemiBold};
    font-size: 16px;
    color: ${COLORS.COLOR_DARK};
    text-align: center;
  }
`;
export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.COLOR_DARK};
`;
