import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { makeStyles, Tooltip } from "@material-ui/core";
import React from "react";

export const Container = styled.div`
  padding: 20px 15px 35px 15px;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 43px;
  }
`;

export const SuperContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;
export const Heading = styled.span`
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
`;
export const Subheading = styled.span`
  max-width: 576px;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  line-height: 1.29;
  margin-top: 20px;
  margin-bottom: 40px;
`;
export const FormContainer = styled.form``;

export const FormControl = styled.div`
  width: 100%;
  &.radio-group {
    width: 100%;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  text-transform: capitalize;
  color: ${COLORS.INPUT_LABEL};
  margin-right: 8px;
`;

const Flex = styled.div`
  margin-top: 20px;
  margin-bottom: ${({ spacing }) => (spacing ? "10px" : "0px")};
  display: flex;
  align-items: center;
`;

const TooltipIcon = styled.img`
  /* width: 17px; */
  height: 17px;
  object-fit: contain;
  cursor: pointer;
`;

export const StyledLabel = ({ tooltip, spacing, style, ...props }) => (
  <Flex spacing={spacing} style={style}>
    <FormLabel {...props} />
    {tooltip && (
      <Tooltip title={tooltip} placement="top">
        <TooltipIcon src={require("../../../assets/images/toolTip.png")} />
      </Tooltip>
    )}
  </Flex>
);

export const StyledLabelAgent = ({ tooltip, spacing, style, ...props }) => (
  <Flex spacing={spacing} style={style}>
    <FormLabel {...props} />
    {tooltip && (
      <Tooltip title={tooltip} placement="top">
        <TooltipIcon src={require("../../../assets/images/toolTip.png")} />
      </Tooltip>
    )}
  </Flex>
);

export const useStyles = makeStyles(() => ({
  root: {},
}));

export const SampleText = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  margin-top: 10px;
  span {
    text-decoration: underline;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
export const Pad = styled.div`
  width: 20px;
  opacity: 0;
`;

export const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: default;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxFormControl = styled.div`
  margin-top: 15px;
`;

export const HalfWidth = styled.div`
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: 47%;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  @media ${theme?.breakpoints?.sm_up} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Margin = styled.div`
  margin-top: 15px;
  @media ${theme?.breakpoints?.sm_up} {
    margin-top: 0;
  }
`;
