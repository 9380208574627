import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { makeStyles } from "@material-ui/core";
import React from "react";

export const OuterContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Container = styled.div`
  padding: 20px 15px;
  display: flex;
  flex-direction: column;
  & .delete {
    display: flex;
    cursor: pointer;
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 43px;
  }
`;
export const BackArrow = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14.5px;
  cursor: pointer;
`;
export const Heading = styled.span`
  display: flex;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: 18px;
  color: ${COLORS.COLOR_DARK};
  width: 100%;
  @media (max-width: 400px) {
    font-size: 16px;
  }
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;
export const Subheading = styled.span`
  max-width: 576px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  line-height: 1.29;
  margin-top: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
`;
export const FormContainer = styled.form`
  /* width: 907px; */

  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${theme?.breakpoints?.md_up} {
    width: auto;
  }
`;
export const TableContainer = styled.div`
  margin-top: 20px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 37px 25px 27px;
    border-radius: 5px;
    border: solid 0.5px ${COLORS.INPUT_BORDER};
  }
`;

export const FormControl = styled.div`
  margin-bottom: 13px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 436px;
  }
`;

export const FormLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  text-transform: uppercase;
  color: ${COLORS.INPUT_LABEL};
  margin-right: 8px;
`;

export const BasicDetailsLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;

export function StyledLabel({ children, required, ...props }) {
  return (
    <FormLabel
      {...props}
      style={{
        fontFamily: theme.fonts.primaryFontSemiBold,
        fontSize: 10,
        color: COLORS.INPUT_LABEL,
      }}
    >
      {children}
      {required && <span className="required-star"> *</span>}
    </FormLabel>
  );
}

export const useStyles = makeStyles(() => ({
  root: {},
}));

export const SampleText = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  margin-top: 10px;
  span {
    text-decoration: underline;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;
export const Pad = styled.div`
  width: 20px;
  opacity: 0;
`;

export const CheckBoxLabel = styled.span`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
`;
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const CheckboxFormControl = styled.div`
  margin-top: 15px;
  width: 436px;
`;

export const HalfWidth = styled.div`
  width: 47%;
`;

export const TextCenter = styled.p`
  width: 436px;
  display: flex;
  align-items: center;
  justify-content: center;
  & h2 {
    margin-bottom: 10px;
  }
`;
export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.83;
  margin-bottom: 17px;
`;
export const GreenLink = styled.button`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.BTN_GREEN};
  font-size: 14px;
  font-weight: bold;
  border: none;
  background: none;
  outline: none;
`;

export const TableWrapper = styled.div`
  @media ${theme?.breakpoints?.md_up} {
    background-color: #f9f9f9;
    border-radius: 7px;
  }
`;

export const HyperLink = styled.span`
  cursor: pointer;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;
export const ButtonWrapper = styled.div`
  width: fit-content;
  @media ${theme?.breakpoints?.md_up} {
    justify-content: flex-start;
    display: flex;
    width: 200px;
    padding-bottom: 20px;
    padding-top: 7px;
  }
`;

export const DeleteIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
  cursor: pointer;
`;

export const MonetaryText = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
  margin-top: 14px;
  span {
    font-size: 16px;
  }

  @media ${theme?.breakpoints?.md_up} {
    align-self: flex-end;
    margin-right: 130px;
    margin-top: 17px;
  }
`;

export const Seperator = styled.div`
  height: 18px;
  width: 1px;
  background-color: ${COLORS.INPUT_BORDER};
`;

export const Text = styled.div`
  display: flex;
  @media ${theme?.breakpoints?.md_up} } {
    margin-right: 30px;
  }
`;

export const BigTable = styled.div`
  display: none;
  @media ${theme?.breakpoints?.md_up} {
    display: block;
  }
`;

export const SmallTable = styled.div`
  display: block;
  @media ${theme?.breakpoints?.md_up} {
    display: none;
  }
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 20px;
  &:nth-child(5n) {
    margin-bottom: 15px;
  }
`;

export const Label = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 10px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.7;
  margin-bottom: 6px;
  color: ${COLORS.INPUT_LABEL};
  text-align: left;
  & span {
    padding: 0px;
  }
`;

export const Value = styled.div`
  height: 32px;
  display: flex;
  align-items: center;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: #293461;
  & span {
    padding: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
    position: absolute;
  }
`;

export const Details = styled.div`
  width: 100%;
  display: flex;
  padding-left: 5px;
  flex-wrap: wrap;
  height: 232px;
  padding: 10px 3.3px 10px 9px;
  border-radius: 10px;
  background-color: #f9f9f9;
  margin-bottom: 15px;
  position: relative;
`;

export const Delete = styled.div`
  font-family:${theme.fonts.primaryFontBold};
  font-size: 12px;
  color: ${COLORS.WARNING_COLOR};
  position absolute;
  bottom:0;
  right:0;
  margin-bottom:10px;
  margin-right:5px;
`;
