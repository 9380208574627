import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  cursor: pointer;
  display: table;
  font-size: 14px;
  width: 100%;
  @media (min-width: 780px) {
    font-size: 12px;
    width: auto;
  }
`;
