import React from "react";
import { H1 } from "../Styles";

const CaseHeader = ({ resolutionKind, onClick, showDelete }) => {
  return (
    <div className="heading">
      <H1>
        Create New Case (1/{" "}
        {resolutionKind === "mediation" || resolutionKind === "arbitration"
          ? 2
          : 3}
        )
      </H1>
      {showDelete && (
        <div className="delete" onClick={onClick}>
          <img
            src={require("../../../../assets/images/delete.svg")}
            alt="delete Icon"
          />
        </div>
      )}
    </div>
  );
};

export default CaseHeader;
