import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import _ from "lodash";

const Index = ({
  items = [],
  selectedIndex,
  isMediation = false,
  resolutionKind,
  kind,
  status,
  currentStateKey,
}) => {
  const [caseCycle, setCaseCycle] = useState([]);

  useEffect(() => {
    let duplicateList = [];
    // eslint-disable-next-line array-callback-return
    let cycleList = items.filter((item) => {
      if (!duplicateList.some((el) => el === item.index)) {
        duplicateList.push(item.index);
        return item;
      }
    });
    cycleList = cycleList.map((item) => {
      if (isMediation) {
        if (item?.label === "Negotiation Ongoing") {
          return { ...item, label: `${_.capitalize(resolutionKind)} Ongoing` };
        } else if (item?.label === "Negotiation Concluded") {
          if (resolutionKind === "arbitration") {
            if (currentStateKey === "finalAward") {
              return {
                ...item,
                label: "Reserved for Award",
              };
            }
            return {
              ...item,
              label: `${_.capitalize(resolutionKind)} Concluded`,
            };
          }
          return {
            ...item,
            label: `${_.capitalize(resolutionKind)} Concluded`,
          };
        } else if (
          item?.label === "Agreement" &&
          resolutionKind === "arbitration"
        ) {
          return {
            ...item,
            label: "Final Award",
          };
        } else {
          if (resolutionKind === "arbitration") {
            if (item.label === "Awaiting Respondent onboarding") {
              return {
                ...item,
                label: "Case Preparation",
              };
            } else if (item.label === "Case Accepted/Preparation") {
              if (
                currentStateKey === "firstHearingIntimation" ||
                currentStateKey === "filingStatementofClaim" ||
                currentStateKey === "filingofSection17" ||
                currentStateKey === "section17OrderPassed" ||
                currentStateKey === "filingofStatementofDefence" ||
                currentStateKey === "rejoinderfromClaimant" ||
                currentStateKey === "surrejoinderFromRespondent" ||
                currentStateKey === "2ndNoticeMOM" ||
                currentStateKey === "crossExaminationClaimantWitness" ||
                currentStateKey === "crossExaminationRespondentWitness" ||
                currentStateKey === "arguments" ||
                currentStateKey === "reservedForAward"
              ) {
                return {
                  ...item,
                  label: "Acceptance by Arbitrator",
                };
              }
              return {
                ...item,
                label: "Notice to Arbitrate",
              };
            }
          }
          return item;
        }
      } else {
        return item;
      }
    });
    setCaseCycle(cycleList);
  }, [items, isMediation, resolutionKind, currentStateKey]);

  return (
    <LifecycleWrapper>
      <BigScreen>
        {caseCycle.map((item, index) => (
          <LifecycleBorder
            isFirst={index === 0}
            selected={selectedIndex > index}
            key={index}
          >
            <Lifecycle isFirst={index === 0} selected={selectedIndex > index}>
              {selectedIndex - 1 === index ? (
                <TextContainer>
                  {currentStateKey === "arbitrationReference" ||
                  currentStateKey === "noticeToArbitrate" ||
                  currentStateKey === "appointmentOfArbitrator" ||
                  currentStateKey === "acceptanceByArbitrator" ||
                  currentStateKey === "firstHearingIntimation" ||
                  currentStateKey === "filingStatementofClaim" ||
                  currentStateKey === "filingofSection17" ||
                  currentStateKey === "section17OrderPassed" ||
                  currentStateKey === "filingofStatementofDefence" ||
                  currentStateKey === "rejoinderfromClaimant" ||
                  currentStateKey === "surrejoinderFromRespondent" ||
                  currentStateKey === "2ndNoticeMOM" ||
                  currentStateKey === "crossExaminationClaimantWitness" ||
                  currentStateKey === "crossExaminationRespondentWitness" ||
                  currentStateKey === "arguments" ||
                  currentStateKey === "reservedForAward"
                    ? status
                    : item.label}
                </TextContainer>
              ) : (
                <TextContainer>{item.label}</TextContainer>
              )}
            </Lifecycle>
          </LifecycleBorder>
        ))}
      </BigScreen>
      <SmallScreen>
        <Row>
          {caseCycle.map((item, index) => (
            <MiniContainer {...{ selectedIndex, index, caseCycle }}>
              <div>
                <Border
                  isFirst={index === 0}
                  selected={selectedIndex > index}
                  key={index}
                >
                  <Cycle isFirst={index === 0} selected={selectedIndex > index}>
                    <Text>{item.index}</Text>
                  </Cycle>
                </Border>
              </div>
              {caseCycle?.length !== index + 1 ? (
                <div className="border"></div>
              ) : null}
            </MiniContainer>
          ))}
        </Row>
        {caseCycle
          .filter((item) => {
            return selectedIndex === item.index;
          })
          .map((item) => {
            return (
              <Text
                style={{
                  color: `${COLORS.COLOR_DARK}`,
                  textTransform: "uppercase",
                }}
              >
                {item.label}
              </Text>
            );
          })}
      </SmallScreen>
    </LifecycleWrapper>
  );
};

export default Index;

const SmallScreen = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.lg_up} {
    display: none;
  }
`;

const MiniContainer = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  ${({ caseCycle, index }) =>
    caseCycle?.length === index + 1 &&
    css`
      width: auto;
    `}
  & .border {
    width: 100%;
    height: 3px;
    background: #acb1c2;
    ${({ selectedIndex, index }) =>
      selectedIndex > index + 1 &&
      css`
        background-color: ${COLORS.COLOR_DARK};
      `}
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 19px 0px 30px 0;
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 600px;
  }
`;
const BigScreen = styled.div`
  display: none;
  @media ${theme?.breakpoints?.lg_up} {
    display: flex;
  }
`;

const Border = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #acb1c2;
  ${({ selected }) =>
    selected &&
    css`
      background-color: ${COLORS.COLOR_DARK};
    `}
`;

const Cycle = styled.div`
  height: 100%;
`;

const Text = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.6;
  color: ${COLORS.PRIMARY_WHITE};
  @media ${theme?.breakpoints?.sm_up} {
    max-width: 600px;
  }
`;

const LifecycleWrapper = styled.div`
  margin: 5px 0;
  display: flex;
`;
const LifecycleBorder = styled.div`
  width: 160px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-left: -16px;
  clip-path: ${({ isFirst }) =>
    isFirst
      ? "polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0% 100%)"
      : "polygon(85% 0, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%)"};
  ${({ selected }) =>
    !selected &&
    css`
      background: ${COLORS.BORDER_GREY};
    `}
  ${({ isFirst }) =>
    isFirst &&
    css`
      margin-left: 0px;
    `}
`;

const Lifecycle = styled.div`
  width: 158px;
  height: 59px;
  border-radius: 4px;
  background-color: ${COLORS.PRIMARY_WHITE};
  clip-path: ${({ isFirst }) =>
    isFirst
      ? "polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0% 100%)"
      : "polygon(85% 0, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%)"};
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ selected }) =>
    selected &&
    css`
      margin-left: unset;
      background-color: ${COLORS.COLOR_DARK};
      & div {
        color: ${COLORS.PRIMARY_WHITE};
      }
    `}

  &:last-child {
    & div {
      text-align: center;
    }
  }
`;
const TextContainer = styled.div`
  width: 90px;
  text-align: left;
  text-transform: capitalize;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 10px;
  line-height: 1.6;
  color: ${COLORS.INPUT_LABEL};
`;
