import React, { useEffect, useState } from "react";
import labels from "../../../helpers/labels.json";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { CircularProgress } from "@material-ui/core";
import DashboardSerivces from "../../../services/DashboardSerivces";
import Items from "./items";
import _ from "lodash";
import queryString from "query-string";
import { navigate, useLocation } from "@reach/router";
import {
  NOTIFICATION_ACTIVITY,
  PAYMENT_PENDING,
} from "../../../helpers/constants";
import useNotification from "../../../hooks/useNotification";
import InfiniteScroll from "react-infinite-scroller";

const Notification = () => {
  const [currentPage, setCurrentPage] = useState(0); // This state is used for indicating the current page
  const [loading, setLoading] = useState(true); // This State for showing the loader for pending action
  const [activities, setActivities] = useState([]); //This state for Pending Action
  const [endPage, setEndPage] = useState();
  const location = useLocation();
  const { setTriggerNotify } = useNotification(); // hooks to refresh the page

  useEffect(() => {
    const getPendingAction = () => {
      let params = {
        page: currentPage + 1,
        perPage: 25,
      };
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      DashboardSerivces.getPendingAction(stringParams).then(
        (res) => {
          if (res?.activities) {
            setActivities([...activities, ...res.activities.data]);
            setEndPage(res.activities.lastPage);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    };

    setLoading(true);
    getPendingAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  const navigateToAction = ({ kind, entityId, meta }) => {
    if (kind && PAYMENT_PENDING.includes(kind)) {
      navigate("/dashboard/cart");
    } else {
      navigate(
        `/dashboard/cases/${entityId}?caseType=${meta?.resolutionKind ? meta?.resolutionKind : "negotiation"
        }`
      );
      if (location.pathname.includes("dashboard/cases")) {
        setTimeout(() => {
          setTriggerNotify(true);
        });
      }
    }
  };

  return (
    <Container>
      <Heading>{labels["appbar.notification"]}</Heading>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMore}
        initialLoad={false}
        hasMore={endPage && currentPage < endPage - 1 ? true : false}
        threshold={50}
        loader={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <CircularProgress size={20} />
          </div>
        }
      >
        <Notifications>
          <div>
            {activities?.length ? (
              <div>
                {activities.map((el, index) => (
                  <div
                    key={index}
                    onClick={() => navigateToAction(el)}
                    style={{ marginBottom: 15 }}
                  >
                    <Items
                      type={NOTIFICATION_ACTIVITY[el?.kind]?.type}
                      message={`${el.message} ${PAYMENT_PENDING.includes(el.kind)
                          ? labels.pay_here
                          : labels.view_here
                        }`}
                      time={el?.created_at}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div></div>
            )}
            <div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "60vh",
                  }}
                >
                  <CircularProgress size={40} />
                </div>
              ) : null}
            </div>
          </div>
        </Notifications>
      </InfiniteScroll>
    </Container>
  );
};

export default Notification;

export const Container = styled.div`
  padding: 50px 15px;
  display: flex;
  flex-direction: column;
`;

export const Heading = styled.div`
  font-size: 18px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  color: ${COLORS.COLOR_DARK};
  margin-bottom: 30px;
`;

export const Notifications = styled.div``;
