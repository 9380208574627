import { FormLabel } from "@material-ui/core";
import React from "react";
import NumberFormat from "react-number-format";
import COLORS from "../../../../assets/Colors";
import theme from "../../../../assets/theme";
import { CustomInputField } from "../../../common/FormInputs";
import { StyledFormElementsWrapper } from "../Styles";

function TotalClaimValue({
  ressolutionKind,
  values,
  handleChange,
  errors,
  onBlur,
  currencyType,
  disabled,
  label,
  name,
}) {
  return (
    <>
      {(ressolutionKind === "mediation" ||
        ressolutionKind === "arbitration") && (
        <StyledFormElementsWrapper>
          <FormLabel
            error={errors?.name}
            style={{
              fontFamily: theme.fonts.primaryFontSemiBold,
              fontSize: 12,
              color: COLORS.INPUT_LABEL,
            }}
          >
            {`${label ? label : "Total"} Claim Value`} ({currencyType})
            {<span className="required-star"> *</span>}
            <span className="waring-msg">
              {" "}
              (Leave claim value as zero if your claim is non-monetary)
            </span>
          </FormLabel>
          <NumberFormat
            thousandSeparator
            thousandsGroupStyle="lakh"
            isNumericString={true}
            decimalScale={2}
            fixedDecimalScale
            allowNegative={false}
            className={"input-white"}
            displayType={"input"}
            value={values}
            disabled={disabled}
            type={"text"}
            customInput={CustomInputField}
            name={name}
            onChange={handleChange}
            variant="outlined"
            helperText={errors?.name}
            error={errors?.name}
            onBlur={onBlur}
          />
        </StyledFormElementsWrapper>
      )}
    </>
  );
}

export default TotalClaimValue;
