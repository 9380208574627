import React, { useEffect, useState } from "react";
import ActionBar from "../../common/ActionBar";
import CardCount from "../../common/CardCount";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import Tabs from "../Tabs";
import { navigate } from "@reach/router";
import DashboardSerivces from "../../../services/DashboardSerivces";
import _ from "lodash";
import queryString from "query-string";
import useCartCount from "../../../hooks/useCartCount";
import useLoader from "../../../hooks/useLoader";
import CaseService from "../../../services/CaseService";
import { getErrorMessage } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import labels from "../../../helpers/labels.json";
import useUser from "../../../hooks/useUser";

const Index = () => {
  const [activities, setActivities] = useState([]); //This state for Pending Action
  const [loading, setLoading] = useState(true); // This State for showing the loader for pending action
  const [currentPage, setCurrentPage] = useState(0); // This state is used for indicating the current page
  const [endPage, setEndPage] = useState();
  const { setTriggerCount } = useCartCount();
  const { enqueueSnackbar } = useSnackbar();
  const { userObject } = useUser();
  const [dashboardCount, setDashboardCount] = useState({
    activeCount: 0,
    caseInvitesReceived: 0,
    pendingInvites: 0,
  });
  const { setLoader } = useLoader();

  const badges = [
    {
      value: 15,
      label: labels.active_cases,
      path: "/dashboard/cases?status=Under Resolution",
      key: "activeCount",
    },
    {
      value: 5,
      label: labels.case_invites,
      path: "/dashboard/invites",
      key: "pendingSentInvites",
    },
    {
      value: 5,
      label: labels.case_invites_received,
      path: "/dashboard/requests",
      key: "pendingReceivedInvites",
    },
    {
      value: 5,
      label: labels.case_agreement,
      path: "/dashboard/cases?status=settled",
      key: "settledTotal",
    },
  ];

  const actions = [
    {
      text: labels["actions.createBulk"],
      props: {
        onClick: () => {
          navigate("/dashboard/cases/bulk-create");
        },
      },
    },
    {
      text: labels.create_party,
      props: {
        onClick: () => {
          navigate("/dashboard/parties/create");
        },
      },
    },
    {
      text: labels.create_case,
      props: {
        onClick: () => navigate("/dashboard/cases/create"),
      },
    },
  ];

  useEffect(() => {
    const getPendingAction = () => {
      setTriggerCount(true);
      let params = {
        page: currentPage + 1,
        perPage: 25,
      };
      let stringParams = "";
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(params)}`;
      }
      DashboardSerivces.getPendingAction(stringParams).then(
        (res) => {
          if (res?.activities) {
            setActivities([...activities, ...res.activities.data]);
            setEndPage(res.activities.lastPage);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    };
    if (!_.isEmpty(userObject) && userObject?.emailVerified) {
      getPendingAction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, userObject]);

  useEffect(() => {
    async function getDashboardCount() {
      try {
        setLoader({ state: true });
        const response = await CaseService.dashboardCount();
        setDashboardCount(response);
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (!_.isEmpty(userObject) && userObject?.emailVerified) {
      getDashboardCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userObject]);

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
  };

  return (
    <div>
      <ActionBar {...{ actions }} />
      <CardCount dashboardCount={dashboardCount} cards={badges} />
      <UpdatesWrapper className="dashboard">
        {/* <h2>{labels.updates}</h2> */}
        <Tabs
          activities={activities}
          loading={loading}
          value={0}
          lastPage={endPage}
          currentPage={currentPage}
          loadMore={loadMore}
        />
      </UpdatesWrapper>
    </div>
  );
};

export default Index;

const UpdatesWrapper = styled.div`
  padding: 8px 15px 18px 15px;
  & h2 {
    margin-top: 20px;
    margin-bottom: 14px;
    font-family: ${theme.fonts.primaryFontBold};
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.69px;
    color: ${COLORS.COLOR_DARK};
  }
  @media ${theme?.breakpoints?.sm_up} {
    padding: 11px 53px 11px 33px;
  }
`;
