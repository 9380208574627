import { FormControl, withStyles } from "@material-ui/core";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

const StyledInput = {
  root: {
    width: "100%",
    fontSize: "16px",
    color: `${COLORS.COLOR_DARK} !important`,
    fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    // background: COLORS.INPUT_BACKGROUND,
    "& .MuiPickersToolbar-toolbar": {
      backgroundColor: COLORS.BTN_GREEN,
    },
    "& label": {
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    },
    "& label.Mui-focused": {
      color: COLORS.COLOR_DARK,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
    "& input": {
      letterSpacing: 0.1,
      fontSize: "16px",
      color: `${COLORS.COLOR_DARK} !important`,
      fontFamily: `${theme.fonts.primaryFontSemiBold}`,
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: COLORS.INPUT_BACKGROUND,
      height: 43,
      "& fieldset": {
        borderRadius: "4px",
        border: "2px solid #acb1c2",
      },
      "&.Mui-focused fieldset": {
        border: "2px solid #acb1c2",
      },
    },
    "& .MuiFormHelperText-root": {
      position: "absolute",
      bottom: -17,
      left: -10,
      fontFamily: theme.fonts.primaryFontSemiBold,
      fontSize: 10,
    },
    "& .MuiSelect-select": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontRegular,
    },
    "& .MuiSelect-outlined": {
      color: "black",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    "& .MuiMenuItem-root": {
      fontSize: "16px",
      fontFamily: theme.fonts.primaryFontSemiBold,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: COLORS.INPUT_BORDER,
      border: `1px solid ${COLORS.INPUT_BORDER}`,
    },
  },
};

export const StyledSelectFormControl = withStyles({
  ...StyledInput,
  root: {
    ...StyledInput.root,
    width: "100%",
    color: "#acb1c2",
  },
})(FormControl);

export const StyledDropdownIcon = styled.img`
  height: 4px;
  width: 7px;
  background-color: ${COLORS.INPUT_BACKGROUND};
  position: absolute;
  right: 13px;
`;

export const HorizontalContainer = styled.div`
  display: flex;
`;
export const CardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  width: 73%;
  padding: 10px;
  @media (max-width: 1024px) {
    margin: auto;
    width: 100%;
  }
`;

export const HeadWrap = styled.div`
  text-align: center;
  font-size: 25px;
  padding-top: 1%;
  padding-left: 2%;
  padding-right: 2%;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 30px;
  }
`;

export const SubHead = styled.div`
  text-align: center;
  font-size: 20px;
  margin: 20px 10px;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const HiddenDiv = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: auto;
  width: 90%;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 0 20px;
  }

  @media ${theme?.breakpoints?.lg_up} {
    visibility: hidden;
    position: absolute;
  }
`;

export const FilterBarContainer = styled.div`
  display: flex;
  position: fixed;
  height: 100vh;
  flex-direction: column;
  margin: auto;
  align-items: flex-start;
  width: 27%;
  font-size: 14px;
  text-align: left;
  padding: 20px 20px 100px;
  background-color: #fff;
  box-shadow: 5px 0 8px #00000015;
  overflow-y: auto;
  ${"" /* border-right: #acb1c2 solid 1px; */}
  z-index: 0;
  @media (max-width: 1024px) {
    width: 50%;
    z-index: 1;
    height: 100%;
  }
  @media (max-width: 700px) {
    width: 70%;
    z-index: 1;
    height: 100%;
  }
`;

export const SearchBarContainer = styled.div`
  display: flex;
  width: 100%;
  height: unset;
  margin: 20px 0;
  text-align: center;
  border: #acb1c2 solid 2px;
  border-radius: 4px;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const SearchBar = styled.input`
  width: 100%;
  padding: 10px 0;
  border: none;
  background-color: inherit;
  margin: auto 0 0 0;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  font-family: ${theme.fonts.primaryFontRegular};
  &::placeholder {
    color: #acb1c2;
  }
`;
export const SearchIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: auto 10px;
`;

export const FilterHead = styled.span`
  font-size: 20px;
  padding: 10px 0 0 0;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;
export const FilterSubHead = styled.div`
  font-size: 20px;
  padding: 10px 0 0 0;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const ListScroll = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 135px;
  height: 135px;
  margin: 10px 0 20px 0;
  border-radius: 4px;
  ${"" /* background-color:#f9f9f9; */}
  overflow: auto;
  border: #acb1c2 solid 2px;
`;
