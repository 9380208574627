import React, { Fragment } from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import OrderDictation from "./LiveDictation";
import { Refresh } from "@material-ui/icons";
import { Tooltip, Button } from "@material-ui/core";
import { CheckboxContainer, CustomCheckbox } from "../../common/FormInputs";
import { CheckBoxLabel } from "../CreateCase2/Styles";

const ActionBar = ({
  actions = [],
  breadcrumbs = [],
  onBreadcrumbClick = () => {},
  paymentPage = false,
  status,
  timeInterval,
  settings,
  caseId,
  mergedBlobURL,
  setMergedBlobURL,
  disabled,
  setTranscribedData,
  isLocalTranscriptionServer,
  checked,
  setChecked,
  autoRefresh,
}) => {
  return (
    <ActionBarContainer actions={actions}>
      <Breadcrumbs>
        {breadcrumbs.map((breadcrumb, index) => (
          <Fragment key={`breadcrumb-${breadcrumb}-${index}`}>
            <Breadcrumb
              paymentPage={paymentPage}
              onClick={() => onBreadcrumbClick(breadcrumbs[index])}
            >
              {breadcrumb}
            </Breadcrumb>
            {index < breadcrumbs.length - 1 && <Breadcrumb>{">"}</Breadcrumb>}
          </Fragment>
        ))}
      </Breadcrumbs>
      <ActionButtonContainer>
        {autoRefresh ? (
          <CheckboxContainer>
            <CustomCheckbox
              checked={checked}
              onChange={(e) => setChecked(e?.target?.checked)}
            />
            <CheckBoxLabel>{"Auto Refresh"}</CheckBoxLabel>
          </CheckboxContainer>
        ) : null}
        {disabled ? (
          <MuiButton>
            <Tooltip title="Reload" placement="top">
              <Refresh
                style={{ fill: "#00838c" }}
                onClick={() => setTranscribedData([""])}
              />
            </Tooltip>
          </MuiButton>
        ) : null}
        {actions.map((action, index) => (
          <ActionButton
            key={`action-${action.text}-${index}`}
            {...action.props}
            actions={actions}
            outlined={action.outlined}
            disabled={action.disabled}
            hidden={action.hidden}
            breadcrumbs={breadcrumbs}
          >
            {action.text}
          </ActionButton>
        ))}
        {status ? null : (
          <ActionBtn>
            <OrderDictation
              {...{
                timeInterval,
                settings,
                caseId,
                mergedBlobURL,
                setMergedBlobURL,
                isLocalTranscriptionServer,
              }}
            />
          </ActionBtn>
        )}
      </ActionButtonContainer>
    </ActionBarContainer>
  );
};

export default ActionBar;

const ActionBarContainer = styled.div`
  margin: 24px 15px 0px 15px;
  display: flex;
  flex-direction: column-reverse;
  min-height: ${({ actions }) => (actions.length === 0 ? "0" : "55px")};
  @media ${theme?.breakpoints?.sm_up} {
    height: 55px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 53px 0 35px;
    margin: 0;
    border-bottom: 1px solid ${COLORS.BORDER_GREY};
  }
`;

const ActionButton = styled.button`
  border-radius: 4px;
  background-color: ${({ outlined, disabled }) =>
    outlined && disabled
      ? "#cccccc"
      : outlined && !disabled
      ? COLORS.PRIMARY_WHITE
      : COLORS.BTN_GREEN};
  color: ${({ outlined, disabled }) =>
    outlined && disabled
      ? "#ffffff"
      : outlined && !disabled
      ? COLORS.BTN_GREEN
      : COLORS.PRIMARY_WHITE};
  border: ${({ outlined, disabled }) =>
    outlined && disabled
      ? `1px solid #999999`
      : outlined && !disabled
      ? `1px solid ${COLORS.BTN_GREEN}`
      : 0};
  width: 138px;
  height: 30px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  margin-right: ${({ actions }) => (actions.length === 1 ? "0" : "6%")};
  outline: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  &:nth-child() {
    margin-right: 0;
  }

  margin-bottom: ${({ breadcrumbs }) =>
    breadcrumbs.length === 0 ? "0" : "30px"};

  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    margin-left: 20px;
  }
`;

const ActionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;

const Breadcrumbs = styled.div``;

const Breadcrumb = styled.span`
  margin-right: 4px;
  cursor: pointer;
  color: ${COLORS.COLOR_DARK};
  user-select: none;
  font-size: ${({ paymentPage }) => (paymentPage ? "18px" : "14px")};
  font-family: ${({ paymentPage }) =>
    paymentPage
      ? `${theme.fonts.primaryFontExtraBold}`
      : `${theme.fonts.primaryFontSemiBold}`};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 14px;
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;

const ActionBtn = styled.div`
  border-radius: 4px;
  width: 181px;
  height: 30px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 14px;
  text-align: center;
  outline: none;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  margin-left: 23px;
  margin-bottom: 30px;
  @media ${theme.breakpoints.sm_up} {
    margin-bottom: 0px;
  }
`;

const MuiButton = styled(Button)(({ disabled }) => ({
  marginLeft: "1.0em",
  width: "0.5em",
  height: "2.1em",
  border: disabled ? `1px solid #8294a5` : `1px solid ${COLORS.BTN_GREEN}`,
  backgroundColor: COLORS.PRIMARY_WHITE,
  minWidth: 32,
}));
