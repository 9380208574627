import styled, { css } from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const Padding = styled.div`
  padding: 0 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-bottom: 50px;
    padding-left: 53px;
    padding-right: 53px;
  }

  @media ${theme?.breakpoints?.lg_plus_up} {
    padding-top: 30px;
  }
`;

export const BasicDetailsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media ${theme?.breakpoints?.lg_plus_up} {
    width: 700px;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const BasicDetailsItem = styled.div`
  width: 50%;
  margin-bottom: 30px;
  &:nth-child(5) {
    margin-bottom: 0;
  }
  padding-right: 30px;
  @media ${theme?.breakpoints?.lg_plus_up} {
    width: auto;
    margin-bottom: 0;
    padding-right: 0;
  }
`;

export const BasicDetailsLabel = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
  text-transform: capitalize;
`;

export const BasicDetailsValue = styled.div`
  word-break: break-all;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  ${({ transform }) =>
    transform &&
    css`
      text-transform: ${transform};
    `}
`;

export const FieldWrapper = styled.div`
  margin-top: 30px;
  width: 50%;
  &:nth-child(1),
  &:nth-child(2) {
    margin-top: 0;
  }
  padding-right: 30px;
  @media ${theme?.breakpoints?.lg_plus_up} {
    &:nth-child(1),
    &:nth-child(2) {
      margin-top: 30px;
    }
    margin-top: 30px;
    width: auto;
    padding-right: 0;
    margin-right: 20px;
  }
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  min-width: 70px;
`;

export const Strong = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  cursor: pointer;
`;

export const Container = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  padding: 15px;
  margin-top: 30px;
  @media ${theme?.breakpoints?.lg_plus_up} {
    margin: 0;
    padding: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const Address = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  @media ${theme?.breakpoints?.lg_plus_up} {
    width: 700px;
    min-width: 700px;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const PartyTable = styled.div`
  margin-top: 30px;
  @media ${theme?.breakpoints?.lg_plus_up} {
    margin-top: 46px;
  }
`;

export const Heading = styled.div`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  margin-bottom: 20px;
  @media ${theme?.breakpoints?.lg_plus_up} {
    display: none;
  }
`;

export const MobileScreen = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  width: 100%;
  @media ${theme?.breakpoints?.lg_plus_up} {
    display: none;
  }
`;

export const BigScreen = styled.div`
  display: none;
  @media ${theme?.breakpoints?.lg_plus_up} {
    display: block;
  }
`;
