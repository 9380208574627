import { makeStyles } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { navigate } from "@reach/router";
import clsx from "clsx";
import _ from "lodash";
import React, { useState } from "react";
import labels from "../../../helpers/labels.json";
import { sidebar_options } from "./constants";
import {
  HeaderRow,
  Icon,
  LogoutIcon,
  LogoutText,
  Menu,
  Name,
  ProfileImage,
  RowSpacing,
} from "./Styles";

const useStyles = makeStyles({
  list: {
    overflow: "auto",
  },
  fullList: {
    width: "unset",
  },
  padder: {
    paddingTop: "74px",
  },
  sidePadder: {
    paddingLeft: "2%",
    paddingRight: "5%",
    margin: 0,
    boxShadow: "none",
    borderBottom: "1px solid #e8e8e8",
  },
  listPadder: {
    padding: 0,
  },
});

export default function MenuModel({
  setModal,
  hideLinks,
  userObject,
  handleLogout,
  selectedSubItem,
  stats,
}) {
  const [expand, setExpand] = useState(false);
  const classes = useStyles();

  const performanceNavigation = (data) => {
    if (data.hasOwnProperty("listItems")) {
      if (data.title === labels.my_cases) {
        setExpand(!expand);
      } else {
        navigate(data.path);
        setModal(false);
      }
    } else {
      navigate(data.path);
      setModal(false);
    }
  };

  return (
    <div
      className={clsx(classes.list, classes.fullList, classes.padder)}
      role="presentation"
    >
      {hideLinks ? null : (
        <Menu>
          {_.isEmpty(userObject) || !userObject?.emailVerified ? (
            // ||EXCLUDE_URL.some((path) => location?.pathname?.includes(path))
            ""
          ) : (
            <>
              <HeaderRow
                onClick={() => {
                  navigate("/dashboard/profile");
                  setModal(false);
                }}
              >
                <ProfileImage
                  src={
                    userObject.avatarUrl
                      ? userObject.avatarUrl
                      : require("../../../assets/images/userPlaceholder.svg")
                  }
                />
                <Name style={{ fontSize: 20 }}>{userObject.name}</Name>
              </HeaderRow>

              <div>
                {sidebar_options
                  .filter((data) => {
                    return data.title !== "home";
                  })
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      <RowSpacing
                        onClick={() => {
                          performanceNavigation(data);
                        }}
                        key={data.title}
                      >
                        <Icon src={data.blueIcon} />
                        {}
                        <Name>
                          <div className="row flex center-center">
                            <div
                              className="flex flex-1"
                              style={{ marginRight: 15 }}
                            >
                              {_.startCase(data.title)}
                            </div>
                            <div className="mr30 flex">
                              {data.hasOwnProperty("listItems") && (
                                <div style={{ marginTop: 5 }}>
                                  {expand ? <ExpandLess /> : <ExpandMore />}
                                </div>
                              )}
                            </div>
                          </div>
                        </Name>
                      </RowSpacing>

                      {data.hasOwnProperty("listItems") && (
                        <Collapse in={expand} timeout="auto" unmountOnExit>
                          <div style={{ marginLeft: 25 }}>
                            {data.listItems.map((li, index) => (
                              <React.Fragment key={index}>
                                <RowSpacing
                                  key={index}
                                  button
                                  onClick={() => {
                                    navigate(li.path);
                                    setModal(false);
                                  }}
                                >
                                  <Icon src={li.blueIcon} />
                                  <Name style={{ fontSize: 14 }}>
                                    {li.title === selectedSubItem}
                                    {_.startCase(li.title)}
                                  </Name>
                                </RowSpacing>
                              </React.Fragment>
                            ))}
                          </div>
                        </Collapse>
                      )}
                    </React.Fragment>
                  ))}
              </div>

              <Divider
                style={{
                  margin: "25px 0px",
                }}
              />

              <RowSpacing
                onClick={() => {
                  navigate("/dashboard/help");
                  setModal(false);
                }}
              >
                <Icon src={require("../../../assets/images/HelpHeader.svg")} />
                <Name>{labels["appbar.help"]}</Name>
              </RowSpacing>

              <RowSpacing
                style={{ marginBottom: "65px" }}
                onClick={handleLogout}
              >
                <LogoutIcon
                  style={{ marginRight: 10 }}
                  src={require("../../../assets/images/logout.svg")}
                />
                <LogoutText>{labels["appbar.logout"]}</LogoutText>
              </RowSpacing>
            </>
          )}
        </Menu>
      )}
    </div>
  );
}
