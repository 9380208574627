import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import COLORS from "../../../assets/Colors";
import { Center } from "../../../styles/component/style";
import styled from "styled-components";
import theme from "../../../assets/theme";
import { CircularProgress } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroller";
import { PAYMENT_PENDING } from "../../../helpers/constants";
import { navigate } from "@reach/router";
import labels from "../../../helpers/labels.json";
import NotificationsIcon from "@material-ui/icons/Notifications";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <TabStyle
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledText style={{ height: "100%" }}>{children}</StyledText>
      )}
    </TabStyle>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabStyle = styled.div`
  height: 385px;
  overflow: auto;
  border-radius: 11px;
  background-color: ${COLORS.PRIMARY_WHITE};
  border: 1px solid ${COLORS.HEADER_BORDER};
  @media ${theme?.breakpoints?.sm_up} {
    border-radius: 0;
  }
`;

const StyledText = styled.article`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  padding: 16px 0;
  overflow: auto;
`;

const Message = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
`;

const Loader = styled.div`
  display: flex;
  justify-content: center;
`;

const Action = styled.span`
  margin-left: 5px;
  text-decoration: underline;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.PEENDIGN_ACTION_LINK};
  cursor: pointer;
`;

const PendingItems = styled.div`
  padding: 16px 30px 16px 10px;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 16px 27px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    width: "100%",
    height: "100%",
    "& .MuiTabs-indicator": {
      height: "0px !important",
    },
    "& .MuiTab-root": {
      padding: "6px 12px 6px 0px",
    },
    [theme.breakpoints.up(640)]: {
      borderRadius: "11px",
      border: `solid 1px ${COLORS.HEADER_BORDER} `,
      "& .MuiTabs-indicator": {
        height: "4px !important",
      },
      "& .MuiTab-root": {
        padding: "6px 12px",
      },
    },
  },

  appBar: {
    color: COLORS.COLOR_DARK,
    backgroundColor: COLORS.INPUT_BACKGROUND,
    boxShadow: "none",
    [theme.breakpoints.up(640)]: {
      backgroundColor: COLORS.PRIMARY_WHITE,
      borderBottom: `solid 1px ${COLORS.HEADER_BORDER} `,
    },
  },
  "& .MuiTab-root": {
    padding: 0,
  },
}));

export default function SimpleTabs({
  activities = {},
  loading = false,
  value = 0,
  handleChange = () => {},
  loadMore = () => {},
  currentPage = 0,
  lastPage,
}) {
  const classes = useStyles();

  const navigateToAction = ({ kind, entityId, meta }) => {
    if (kind && PAYMENT_PENDING.includes(kind)) {
      navigate("/dashboard/cart");
    } else {
      navigate(
        `/dashboard/cases/${entityId}?caseType=${
          meta?.resolutionKind ? meta?.resolutionKind : "negotiation"
        }`
      );
    }
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} position="static">
        <Tabs
          value={value}
          indicatorColor="primary"
          onChange={handleChange}
          aria-label="pending-action-tab"
        >
          <Tab
            icon={<NotificationsIcon style={{ color: COLORS.LOGOUT_RED }} />}
            label={labels.pending_actions}
            {...a11yProps(0)}
          />
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0}>
        <InfiniteScroll
          pageStart={0}
          loadMore={loadMore}
          initialLoad={false}
          hasMore={lastPage && currentPage < lastPage - 1 ? true : false}
          useWindow={false}
          threshold={50}
          loader={
            <Loader key={0}>
              <CircularProgress />
            </Loader>
          }
        >
          {loading ? (
            <Center style={{ paddingTop: 0 }}>
              <CircularProgress />
            </Center>
          ) : (
            <Fragment>
              {activities?.length > 0 ? (
                <Fragment>
                  {activities?.map((mes, index) => (
                    <PendingItems key={index}>
                      <Message>{mes.message}</Message>
                      <Action onClick={() => navigateToAction(mes)}>
                        {PAYMENT_PENDING.includes(mes.kind)
                          ? labels.pay_here
                          : labels.view_here}
                      </Action>
                    </PendingItems>
                  ))}
                </Fragment>
              ) : (
                <Center
                  style={{
                    height: 385,
                    paddingBottom: 60,
                  }}
                >
                  {labels.pending_actions_empty}
                </Center>
              )}
            </Fragment>
          )}
        </InfiniteScroll>
      </TabPanel>
    </div>
  );
}
