import React from "react";
import styled from "styled-components";
import _ from "lodash";
import { Drawer } from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import CustomTable from "../CustomTable/CustomTable";

export default function AgentListModal({
  modal,
  setModal,
  type = "respondent",
}) {
  const columns = [
    {
      field: "user.name",
      title: "Agent Name",
      sorting: false,
      render: (rowData) => <p>{rowData.user?.name}</p>,
    },
    {
      field: "email",
      title: "Email",
      sorting: false,
      render: (rowData) => <p>{rowData.email}</p>,
    },
    {
      field: "user.mobile",
      title: "Phone",
      sorting: false,
      render: (rowData) => <p>{rowData.user?.mobile}</p>,
    },
  ];

  return (
    <Drawer anchor={"right"} open={modal.state}>
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>{`${_.startCase(type)} `}Agents Details</Heading>
          <CloseModal
            onClick={() => setModal((a) => ({ ...a, state: false }))}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <AgentFormContainer>
          <CustomTable
            pluralTitle="Agent"
            singularTitle=""
            {...{
              columns,
            }}
            state={{
              data: modal?.agents?.filter((agent) => agent?.role === "primary"),
            }}
            data={modal?.agents?.filter((agent) => agent?.role === "primary")}
            noToolbar
            pageSize={10000}
            hidePagination={true}
          />
        </AgentFormContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 600px;
  height: 100%;
  background-color: white;
  outline: none;
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 38px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const AgentFormContainer = styled.p`
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  color: ${COLORS.COLOR_DARK};
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  line-height: 1.71;
  text-align: justify;
  min-height: 200px;
`;
