import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 15px 15.7px 15px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-bottom: 50px;
    padding-left: 35px;
    padding-right: 53px;
  }
`;
export const HyperLink = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  opacity: ${({ disabled }) => (disabled ? "0.5" : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;
export const StyledTitle = styled.span`
  font-family: ${theme.fonts.primaryFontSemiBold};
  width: 300px;
  cursor: pointer;
`;
export const Heading = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 18px;
  font-weight: 800;
  color: ${COLORS.COLOR_DARK};
  margin: auto;
  width: 100%;
  margin-top: 23px;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-between;
  @media ${theme?.breakpoints?.sm_up} {
    justify-content: flex-start;
  }
`;
export const TableWrapper = styled.main`
  margin-bottom: 15px;
  margin-top: -22px;
`;
export const Footer = styled.footer`
  & .highlighted {
    color: red;
    margin-top: 10px;
    font-size: 12px;
    font-family: ${theme.fonts.primaryFontSemiBold};
  }
`;
export const Hr = styled.hr`
  border-width: 0;
  @media ${theme?.breakpoints?.sm_up} {
    border: solid 0.5px ${COLORS.HEADER_BORDER};
  }
`;
export const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  padding: 0 15px;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
    padding: 0;
    flex-direction: row;
    justify-content: flex-end;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const Text = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 12px;
  color: ${COLORS.COLOR_DARK};
`;
export const TextBold = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  margin-left: 4px;
`;

export const Payment = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-bottom: 40px;
  @media ${theme?.breakpoints?.sm_up} {
    flex-direction: row;
    margin-bottom: 0;
  }
`;

export const PayLater = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 30;
`;

export const Button = styled.div`
  margin-top: 15px;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: 216px;
    margin: 0;
    margin-left: 26px;
  }
`;

export const TopCenter = styled.div`
  margin-left: 40px;
  @media (min-width: 780px) {
    margin-left: 0px;
  }
`;

export const Select = styled.div`
  display: block;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
  width: 110px;
  font-family: ${theme.fonts.primaryFontRegular};
  @media ${theme?.breakpoints?.sm_up} {
    display: none;
  }
`;

export const Icon = styled.img`
  margin-right: 10px;
  width: 20px;
  height: 20px;
  color: ${COLORS.COLOR_DARK};
`;
