import React, { useRef, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import theme from "../../../../assets/theme";
import COLORS from "../../../../assets/Colors";
import styled from "styled-components";
import { useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((themes) => ({
  root: {
    marginTop: 10,
    width: "100%",
    display: "flex",
    [themes.breakpoints.up(640)]: {
      justifyContent: "flex-end",
    },
    "& .MuiPaginationItem-root": {
      margin: 0,
      fontFamily: theme.fonts.primaryFontRegular,
      color: COLORS.COLOR_DARK,
      fontSize: 14,
      height: 36,
      width: 36,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .MuiPaginationItem-outlined": {
      border: 0,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      backgroundColor: "#efefef",
      border: "solid 0.1px #bbbbbb;",
      borderRadius: 0,
      fontFamily: theme.fonts.primaryFontBold,
    },
    "& .MuiPagination-ul": {
      border: "solid 0.5px #bbbbbb;",
      borderRadius: 4,
    },
    "& li": {
      borderRight: "solid 0.5px #bbbbbb",
    },
    "& li:last-child": {
      borderRight: 0,
    },
    "& .MuiSvgIcon-root": {
      position: "relative",
      right: "0",
      top: "0",
    },
  },
}));

export default function CustomPagination({ MTRef, state }) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const PaginationRef = useRef();
  const themes = useTheme();
  const isBigScreen = useMediaQuery(themes.breakpoints.up(640));

  function handlePageChange(page) {
    MTRef.current.onChangePage(0, page - 1);
    setPage(page);
  }

  return (
    <div className={classes.root}>
      <NextLast onClick={() => handlePageChange(1)} margin={"right"}>
        First
      </NextLast>
      <Pagination
        ref={PaginationRef}
        count={state?.lastPage}
        onChange={(e, page) => {
          MTRef.current.onChangePage(e, page - 1);
          setPage(page);
        }}
        variant="outlined"
        shape="rounded"
        page={page}
        siblingCount={isBigScreen ? 1 : 0}
        boundaryCount={1}
      />
      <NextLast
        onClick={() => handlePageChange(state?.lastPage)}
        margin={"left"}
      >
        Last
      </NextLast>
    </div>
  );
}

const NextLast = styled.div`
  display: none;
  @media ${theme?.breakpoints?.sm_up} {
    width: 55px;
    height: 36.5px;
    border-radius: 4px;
    border: solid 0.5px #bbbbbb;
    background-color: #f9f9f9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: ${theme.fonts.primaryFontBold};
    color: ${COLORS.COLOR_DARK};
    ${({ margin }) => `margin-${margin}`}: 5px;
    user-select: none;
    cursor: pointer;
  }
`;
