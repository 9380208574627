import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const TimeSlot = styled.div`
  position: absolute;
  color: ${COLORS.COLOR_DARK};
  opacity: 0.5;
  font-size: 10px;
  right: 0px;
  bottom: 0px;
  margin-top: 2px;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const Icon = styled.div`
  height: 40px;
  width: 40px;
  min-width: 40px;
  background: ${(props) => (props.bg ? props.bg : "")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NotificationText = styled.div`
  margin: 0px 50px 0 15px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  font-weight: 600;
  color: ${COLORS.COLOR_DARK};
  white-space: break-spaces;
  @media ${theme?.breakpoints?.sm_up} {
    width: 230px;
    margin: 0px 12px;
    font-size: 12px;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 0;
  }
`;
export const IconHolder = styled.div`
  height: 18px;
  width: 18px;
  margin-right: 10px;
  .icon {
    font-size: 18px;
    color: ${COLORS.BTN_GREEN};
  }
`;
