import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import useCalendar from "../../../hooks/useCalender";
import dayjs from "dayjs";
import { Tooltip } from "@material-ui/core";
import { navigate } from "@reach/router";

export default function Day({ day, rowIdx }) {
  // const [dayEvents, setDayEvents] = useState([]);
  const { filteredEvents } = useCalendar();

  const formatDate = (dateString) => {
    // Split the date string by '/'
    const parts = dateString?.split("/");

    // Construct a new date string in the desired format
    const formattedDate = parts?.length
      ? `${parts[0]}-${parts[1]}-${parts[2].substring(2)}`
      : "";

    return formattedDate;
  };

  // useEffect(() => {
  //   setDayEvents(
  //     filteredEvents.filter(
  //       (evt) => formatDate(evt?.date) === day.format("DD-MM-YY")
  //     )
  //   ); // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [day]);

  const getCurrentDayClass = () => {
    if (day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")) {
      return {
        borderRadius: "50%",
        color: "#ffffff",
        backgroundColor: "#2563EB",
      };
    }
  };

  return (
    <Container>
      <Header>
        {rowIdx === 0 && <Days>{day.format("ddd").toUpperCase()}</Days>}
        <Date style={getCurrentDayClass()}>{day.format("DD")}</Date>
      </Header>
      <div style={{ flex: 1 }}>
        {filteredEvents
          .filter((evt) => formatDate(evt?.date) === day.format("DD-MM-YY"))
          .map((evt, idx) => (
            <Tooltip placement="top" title={evt.title}>
              <EventContainer
                style={{ backgroundColor: evt.color }}
                key={idx}
                onClick={() =>
                  navigate(
                    `/dashboard/cases/${evt.caseId}?caseType=${evt?.resolutionKind}`,
                  )
                }
              >
                {evt.title}
              </EventContainer>
            </Tooltip>
          ))}
        {/* {dayEvents.map((evt, idx) => (
          <Tooltip placement="top" title={evt.title}>
            <EventContainer
              style={{ backgroundColor: evt.color }}
              key={idx}
              onClick={() => setShowEventModal({ state: true, data: evt })}
            >
              {evt.title}
            </EventContainer>
          </Tooltip>
        ))} */}
      </div>
    </Container>
  );
}

const Container = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 216, 240);
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Days = styled.p`
  font-size: 0.875rem;
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
  color: #8294a5;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const Date = styled.p`
  font-size: 0.875rem;
  padding: 0.25rem;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
  width: 20%;
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

const EventContainer = styled.div`
  padding: 0.1rem;
  margin-right: 0.75rem;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontSemiBold};
  border-radius: 0.25rem;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
