import { makeStyles} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React, { useEffect } from 'react';
import COLORS from '../../../assets/Colors';
import theme from '../../../assets/theme';
import styled from "styled-components";

const useStyles = makeStyles(() => ({
    root: {
      marginTop: 10,
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& .MuiPaginationItem-root": {
        margin: 0,
        fontFamily: theme.fonts.primaryFontRegular,
        color: COLORS.COLOR_DARK,
        fontSize: 14,
        height: 36,
        width: 36,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      "& .MuiPaginationItem-outlined": {
        border: 0,
      },
      "& .MuiPaginationItem-page.Mui-selected": {
        backgroundColor: "#efefef",
        border: "solid 0.1px #bbbbbb;",
        borderRadius: 0,
        fontFamily: theme.fonts.primaryFontBold,
      },
      "& .MuiPagination-ul": {
        border: "solid 0.5px #bbbbbb;",
        borderRadius: 4,
      },
      "& li": {
        borderRight: "solid 0.5px #bbbbbb",
      },
      "& li:last-child": {
        borderRight: 0,
      },
    },
  }));
function PaginationForItems({profilesPerPage,totalProfiles, paginate, page}) {
    const pageNumbers=Math.ceil(totalProfiles/profilesPerPage)
    const classes=useStyles();
    useEffect(()=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
    })
    return (
        <div className={classes.root}>
            <NextLast onClick={() => paginate(1)} margin={"right"}>
             First
            </NextLast>
            <Pagination count={pageNumbers} page={page}
            onChange={(e,page) => paginate(page)}
             variant="outlined" shape="rounded" />
             <NextLast
              onClick={() => paginate(pageNumbers)}
              margin={"left"}
            >
        Last
      </NextLast>
        </div>
    );
}

export default PaginationForItems;


const NextLast = styled.div`
  width: 55px;
  height: 36.5px;
  border-radius: 4px;
  border: solid 0.5px #bbbbbb;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  ${({ margin }) => `margin-${margin}`}: 5px;
  user-select: none;
  cursor: pointer;
`;
