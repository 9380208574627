import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { PrimaryCTAButton } from "../../common/Buttons";

export const WidthMax = styled.div`
  width: 100vw;
`;

export const PrimaryStyledButton = styled(PrimaryCTAButton)`
  width: 30%;
  margin: 5px;
  border-radius: 3px;
  font-size: 15px;
  padding: 10px;
  font-family: ${theme.fonts.primaryFontRegular};
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;

export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 30px;
  margin-bottom: 32px;
  cursor: default;
  padding: 0 20px;
  margin: 0 auto;
  @media (max-width: 1024px) {
    font-size: 24px;
    text-align: center;
  }
`;
export const SubHeading = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.BTN_GREEN};
  font-size: 18px;
`;
export const P = styled.p`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
  font-size: 16px;
  line-height: 1.56;
  width: 978px;
  height: unset;
  cursor: default;
  margin-top: 20px;
  & p {
    margin-top: 20px;
  }
  & div {
    margin-left: 40px;
    & span {
      margin-right: 20px;
    }
    @media (max-width: 600px) {
      margin-left: 20px;
      & span {
        margin-right: 10px;
      }
    }
  }
  @media (max-width: 1024px) {
    width: 80%;
  }
`;
export const ImgWrapper = styled.div`
  position: absolute;
  bottom: -50%;
  max-width: 1152px;
  height: 804px;
  @media (max-width: 600px) {
    position: relative;
    bottom: 0;
    width: 100%;
    height: unset;
  }
`;

export const Section = styled.section`
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    p {
      height: unset;
      margin-bottom: 20px;
    }
  }
`;
export const ProductVideos = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1124px;
  margin-top: 45px;
  @media (max-width: 1024px) {
    width: 80%;
    flex-direction: column;
    padding: 0 20px;
  }
`;
export const ProductVideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    iframe {
      height: 190px;
      width: 340px;
    }
  }
`;
export const ProductVideoText = styled.p`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  height: 38px;
  font-size: 14px;
  margin-top: 30px;
  cursor: default;
`;
export const AboutUsBody = styled.section`
  /* height: 663px; */
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    height: unset;
  }
`;
export const GreySection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 60px 0 60px 0;
  align-items: center;
  background-color: #f9f9f9;
  width: 100vw;
`;
export const WhiteSection = styled.section`
  display: flex;
  flex-direction: column;
  padding: 60px 0 60px 0;
  align-items: center;
  background-color: #ffffff;
  width: 100vw;
`;

export const Founders = styled.section`
  display: flex;
  //width: 1078px;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
  background-color: #ffffff00;
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const Founder = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
  text-align: center;
  @media (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
    flex-direction: column;
    width: unset;
    width: 100%;
    height: 360px;
    align-items: center;
    margin: 40px auto;
  }
`;
export const ImageWrapper = styled.div`
  display: flex;
  height: 250px;
  width: 250px;
  margin: auto;
  align-items: center;
`;
export const FounderImg = styled.img`
  height: 200px;
  width: 200px;
  border-radius: 50%;
  margin: auto;
`;
export const FounderName = styled.div`
  font-size: 18px;
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.BTN_GREEN};
  margin-bottom: 13px;
  @media (max-width: 600px) {
    text-align: center;
  }
`;
export const FounderDetails = styled.div`
  width: 269px;
  height: 113px;
  @media (max-width: 600px) {
    text-align: center;
  }
`;
export const FounderDesc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: default;
  @media (max-width: 600px) {
    padding-top: 20px;
  }
`;
export const Bold = styled.div`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
`;
export const Light = styled.div`
  font-size: 14px;
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.COLOR_DARK};
`;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
`;
export const Form = styled.form`
  width: 413px;
  margin: auto;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const FormElWrapper = styled.div``;
export const ButtonWrapper = styled.div`
  width: 414px;
  margin-bottom: 56px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const FooterWrapper = styled.div`
  background-color: #293461;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: unset;
    padding: 20px 0;
  }
`;
export const LogoWrapper = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 20px;
  font-weight: 500;
  cursor: default;
  margin: 33px 0 52px;
  cursor: pointer;
`;
export const CompanyLogoWhite = styled.img`
  width: 78px;
  height: 80px;
`;
export const NavLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0 92px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;
export const NavLink = styled.div`
  margin: 0 19px;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 20px;
  color: ${COLORS.PRIMARY_WHITE};
  transition: 350ms color;
  &:hover {
    color: ${COLORS.INPUT_LABEL};
  }
  @media (max-width: 1024px) {
    padding: 10px 0;
    font-size: 16px;
  }
`;
export const Copyrights = styled.div`
  margin: 0 0 34px;
  opacity: 0.37;
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 16px;
  color: ${COLORS.PRIMARY_WHITE};
  cursor: default;
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 40px;
    text-align: center;
  }
`;
