import React, { useState, useMemo, useEffect, useCallback } from "react";
import ActionBar from "./ActionBar";
import styled from "styled-components";
import { useLocation, navigate } from "@reach/router";
import _ from "lodash";
import useDictation from "../../../hooks/useDictation";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";
import { Slate, Editable, withReact } from "slate-react";
import { createEditor, Transforms } from "slate";
import { withHistory } from "slate-history";
// import { PauseOutlined, PlayArrow } from "@material-ui/icons";
import useLoader from "../../../hooks/useLoader";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../helpers/functions";
import CaseService from "../../../services/CaseService";
import "./Audio.css";
import AWSService from "../../../services/AWSService";
import moment from "moment";

const CrossExamination = (id) => {
  const history = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = [
    "Cases",
    _.startCase(resolutionKind),
    "Cross Examination",
  ];
  const editor = useMemo(() => withReact(withHistory(createEditor())), []);
  const [value, setValue] = useState([]);
  //   const videoRef = useRef(null);
  const {
    comment,
    setComment,
    setTranscribedData,
    transcriptionReceived,
    setTranscriptionReceived,
    setDisabled,
    disabled,
  } = useDictation();
  const { setLoader } = useLoader();
  const { enqueueSnackbar } = useSnackbar();
  const [referesh, setReferesh] = useState(true);
  const [render, setRender] = useState(false);
  const caseId = id?.id;
  const [data, setData] = useState([]);
  const [transcript, setTranscript] = useState();
  const [mergedBlobURL, setMergedBlobURL] = useState();
  const [checked, setChecked] = useState(false);
  const autoRefresh = false;
  const [autoRef, setAutoRef] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case resolutionKind:
        navigate(`/dashboard/cases/${id?.id}?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/dashboard/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (comment) {
      const lastNode = data[data.length - 1];
      const updatedComment = { ...comment };
      updatedComment["textType"] =
        lastNode?.textType === "Question" ? "Answer" : "Question";
      // updatedComment["color"] = "rgba(0, 131, 140, 0.58)";
      updatedComment["color"] = "#5cebdf";
      const updatedArray = [...data, updatedComment];
      updatedArray[updatedArray.length - 1] = updatedComment;
      setTranscript(updatedComment);
      if (updatedArray) {
        const response = splitContent(updatedArray);
        setValue(response);
        editor.children = response;
        editor.selection = null;
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comment]);

  useEffect(() => {
    const getTranscriptionData = async (caseId) => {
      try {
        setLoader({ state: true, message: "Fetching transcript..." });
        const response = await CaseService.getTranscriptionData(caseId);
        if (response?.crossExamination) {
          setData(
            response?.transcriptionData?.map((el) => {
              return {
                speaker: el.speaker ? el?.speaker : "test",
                text: el?.text ? el?.text : "",
                textType: el.textType ? el.textType : "Question",
                mediaId: el?.mediaId,
                questionNo: el?.questionNo,
                audioUrl: el?.audioUrl,
              };
            }),
          );
          setRender(true);
        } else {
          setValue([]);
          setData([]);
          editor.children = [{ type: "paragraph", children: [{ text: "" }] }];
          Transforms.deselect(editor);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        setComment();
        setTranscribedData([]);
        setMergedBlobURL();
        // setDisabled(false);
      }
    };
    if (referesh) {
      getTranscriptionData(caseId);
      setReferesh(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referesh]);

  useEffect(() => {
    if (trigger && comment) {
      const lastNode = data[data.length - 1];
      const updatedComment = { ...comment };
      updatedComment["textType"] =
        lastNode?.textType === "Question" ? "Answer" : "Question";
      // updatedComment["color"] = "rgba(0, 131, 140, 0.58)";
      updatedComment["color"] = "#5cebdf";
      const updatedArray = [...data, updatedComment];
      updatedArray[updatedArray.length - 1] = updatedComment;
      setTranscript(updatedComment);
      if (updatedArray) {
        const response = splitContent(updatedArray);
        setValue(response);
        editor.children = response;
        editor.selection = null;
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trigger]);

  useEffect(() => {
    const getTranscriptionData = async (caseId) => {
      try {
        const response = await CaseService.getTranscriptionData(caseId);
        if (response?.crossExamination) {
          setData(
            response?.transcriptionData?.map((el) => {
              return {
                speaker: el.speaker ? el?.speaker : "test",
                text: el?.text ? el?.text : "",
                textType: el.textType ? el.textType : "Question",
                mediaId: el?.mediaId,
                questionNo: el?.questionNo,
                audioUrl: el?.audioUrl,
              };
            }),
          );
          setTrigger(true);
        } else {
          setValue([]);
          setData([]);
          editor.children = [{ type: "paragraph", children: [{ text: "" }] }];
          Transforms.deselect(editor);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setTrigger(false);
        // setDisabled(false);
      }
    };
    if (autoRef) {
      getTranscriptionData(caseId);
      setAutoRef(false);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRef]);

  useEffect(() => {
    if (checked) {
      const timeInterval = setInterval(
        () => {
          setAutoRef(true);
        },
        id?.location.state.autoRefreshTimeInterval
          ? id?.location.state.autoRefreshTimeInterval * 1000
          : 6 * 1000,
      );
      return () => clearInterval(timeInterval);
    }
  }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      const response = splitContent(data);
      setValue(response);
      setRender(false);
    }
    if (value) {
      editor.history.undos = [];
      editor.children = value;
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [render]);

  useEffect(() => {
    async function postTranscriptionData(caseId) {
      try {
        setLoader({ state: true, message: "Post transcript..." });
        const blobRes = await fetch(mergedBlobURL);
        const blob = await blobRes.blob();
        const dateOfFile = moment(new Date()).format("DD-MM-YYYY_h-mm-ss");
        const file = new File(
          [blob],
          `${caseId}_crossExamAudio_${data.length}_${dateOfFile}.mp3`,
          { type: "audio/mp3" },
        );
        const s3Response = await AWSService.getS3URL({
          key: `${caseId}_crossExamAudio_${data.length}_${dateOfFile}.mp3`,
        });
        const audioUrl = await AWSService.uploadToS3(s3Response.url, file, {
          type: "audio/mp3",
        });
        const payload = {
          transcriptionData: { ...transcript, audioUrl },
          caseId: caseId,
        };
        const response = await CaseService.postTranscriptionData(payload);
        if (response) {
          setReferesh(true);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
        setTranscript();
        setTranscribedData([]);
        transcriptionReceived[caseId] = false;
        setTranscriptionReceived(transcriptionReceived);
        setDisabled(false);
      }
    }

    if (transcriptionReceived[caseId]) {
      postTranscriptionData(caseId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transcriptionReceived[caseId]]);

  const generateText = (words) => {
    return words.text;
  };

  const splitContent = (paragraphs) => {
    return paragraphs.map((paragraph, index) => ({
      speaker: paragraph.speaker,
      id: paragraph.id,
      audioUrl: paragraph.audioUrl,
      textType: paragraph.textType,
      type: "timedText",
      currentTime: paragraph.currentTime,
      duration: paragraph.duration,
      mediaId: paragraph.mediaId,
      questionNo: paragraph.questionNo,
      children: [
        {
          text: generateText(paragraph),
          color: paragraph.color ? paragraph.color : "",
        },
      ],
    }));
  };

  const renderLeaf = useCallback(({ attributes, children, leaf }) => {
    return (
      <span
        className={"timecode text"}
        style={{
          borderRadius: "5px",
          backgroundColor: leaf["color"],
        }}
        {...attributes}
      >
        {children}
      </span>
    );
  }, []);

  const renderElement = useCallback((props) => {
    switch (props.element.type) {
      case "timedText":
        return <TimedTextElement {...props} />;
      default:
        return <DefaultElement {...{ props }} />;
    }
  }, []);

  const DefaultElement = (props) => {
    return <p {...props.attributes}>{props.children}</p>;
  };

  const TimedTextElement = (props) => {
    return (
      <React.Fragment>
        <DrawerContainer contentEditable={false}>
          <ValueSpeaker style={{ color: "#293461" }}>
            {props.element.textType}
          </ValueSpeaker>
          <ValueSpeaker>{props.element.speaker}</ValueSpeaker>
          <ValueConfidence>{props.element.questionNo}</ValueConfidence>
          <AudioBar>
            {props.element.audioUrl ? (
              <div className="audio-player-container">
                <audio className="audio-player" controls>
                  <source src={props.element.audioUrl} />
                </audio>
              </div>
            ) : null}
            {/* <audio
                id="audioUrlId"
                ref={videoRef}
                src={props.element.audioUrl}
                onLoadedMetadata={(event) =>
                  handleMetaDataTimeUpdate(event, props.element)
                }
                // controls
                // playsInline
                // style={{
                //   display: "inline",
                // }}
              />
              <ButtonPlayer onClick={() => togglePlay(props.element)}>
                {props.element.media ? <PauseOutlined /> : <PlayArrow />}
              </ButtonPlayer>
              <Bar
                type="range"
                min="0"
                style={{
                  accentColor: COLORS.BTN_GREEN,
                  // color: "rgba(80, 151, 255, 0.38)",
                }}
                max={props.element.duration}
                value={props.element.currentTime}
              /> */}
          </AudioBar>
        </DrawerContainer>
        <CustomTextAreaOtter
          style={{
            color: COLORS.COLOR_DARK,
          }}
        >
          {props.children}
        </CustomTextAreaOtter>
      </React.Fragment>
    );
  };

  const actions = [
    {
      text: "Refresh",
      props: {
        onClick: () => setReferesh(true),
      },
      outlined: true,
      disabled: disabled,
      // hidden: transcriptData?.audioFileUrl && data ? true : false,
    },
  ];

  return (
    <>
      <React.Fragment>
        <ActionBar
          timeInterval={id?.location.state.timeInterval}
          isLocalTranscriptionServer={
            id?.location?.state?.isLocalTranscriptionServer
          }
          settings={id?.location?.state}
          {...{
            actions,
            breadcrumbs,
            onBreadcrumbClick,
            caseId,
            mergedBlobURL,
            setMergedBlobURL,
            disabled,
            setTranscribedData,
            checked,
            setChecked,
            autoRefresh,
          }}
        />
        <Container>
          {value.length !== 0 ? (
            <div style={{ paddingTop: "10px" }}>
              <BoxContainer>
                <Main>
                  {value.length !== 0 ? (
                    <Slate editor={editor} value={value}>
                      <Editable
                        readOnly={true}
                        autoFocus
                        renderElement={renderElement}
                        renderLeaf={renderLeaf}
                      />
                    </Slate>
                  ) : null}
                </Main>
              </BoxContainer>
            </div>
          ) : (
            <StyledTableEmptyBlock>
              <h2>
                {
                  "Welcome to IIAC live transcription services. Your session has either not started or your live transcript is currently being generated."
                }
              </h2>
            </StyledTableEmptyBlock>
          )}
        </Container>
      </React.Fragment>
    </>
  );
};

export default CrossExamination;

export const Container = styled.div`
  padding: 4px 43px;
  display: flex;
  flex-direction: column;
`;

const Main = styled.section`
  background-color: ${COLORS.PRIMARY_WHITE};
  height: 590px;
  position: relative;
  overflow-y: scroll;
  scrollbar-width: thin;
  border-radius: 10px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  box-shadow: 0 0 5px rgba(80, 151, 255, 0.68);
  @media ${theme.breakpoints.sm_up} {
    height: 590px;
  }
`;

const DrawerContainer = styled.div`
  width: 100%;
  padding: 10px 23px;
  display: flex;
  align-items: center;
`;

const ValueSpeaker = styled.span`
  width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  padding-right: 6px;
  padding-left: 10px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

export const StyledDropdownIcon = styled.img`
  height: 4px;
  width: 7px;
  background-color: ${COLORS.INPUT_BACKGROUND};
  position: absolute;
  right: 13px;
`;

const BoxContainer = styled.div`
  background-color: ${COLORS.BORDER_GREY};
  position: relative;
  border-radius: 10px;
`;

export const LabelValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15px;
  margin-bottom: 20px;
  &:nth-child(5n) {
    margin-bottom: 25px;
  }
`;

export const Value = styled.div`
  height: 10px;
  display: block;
  align-items: center;
  margin-bottom: 20px;
`;

const CustomTextAreaOtter = styled.div`
  width: 80%;
  font-size: 16px;
  margin-left: 125px;
  margin-right: 25px;
  margin-bottom: 8px;
  line-height: 1.5;
  outline: none;
  border: none;
  resize: none;
  font-family: ${theme.fonts.primaryFontSemiBold};
  background-color: ${COLORS.PRIMARY_WHITE};
  @media ${theme.breakpoints.sm_down} {
    margin-left: 30px;
    font-size: 14px;
  }
`;

const AudioBar = styled.span`
  width: 75%;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  display: flex;
  align-items: center;
  margin-right: 10px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const ValueConfidence = styled.span`
  width: 48px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  line-height: 1.7;
  margin-right: 10px;
  color: ${COLORS.TRANSCRIPT_GRAY};
  word-break: break-all;
  padding-right: 6px;
  @media ${theme.breakpoints.sm_down} {
    font-size: 14px;
  }
`;

const StyledTableEmptyBlock = styled.div`
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-40%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: ${COLORS.COLOR_DARK};
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 20px;
    margin: 0;
    text-align: center;
    font-family: ${theme.fonts.primaryFontBold};
  }
  p {
    margin: 10px auto 30px;
    font-family: ${theme.fonts.primaryFontRegular};
    color: ${COLORS.COLOR_DARK};
  }
  a {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme.fonts.primaryFontRegular};
  }
`;
