import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";

const TimeBox = ({ currentTime, duration, promptSetCurrentTime }) => {
  const handleClick = (e) => {
    promptSetCurrentTime(e);
  };

  return (
    <TimeBoxs>
      <CurrentTime title="Current time: alt t" onClick={() => handleClick()}>
        {currentTime}
      </CurrentTime>
      <Separator>|</Separator>
      <Duration title="Clip duration">{duration}</Duration>
    </TimeBoxs>
  );
};

export default TimeBox;

export const TimeBoxs = styled.div`
  display: inline-block;
  text-align: center;
  border-radius: 5px;
  line-height: 38px;
  padding: 0 2em;
  margin-right: 0.3rem;
  margin-top: 0.2rem;
  background-color: #00838c;
  @media ${theme.breakpoints.sm_down} {
    width: 80%;
    display: grid;
    padding: 0px;
    padding-right: 5px;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
  }
`;
export const CurrentTime = styled.span`
  color: #ffffff;
  cursor: pointer;
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme.breakpoints.sm_down} {
    margin-right: 16px;
  }
`;

export const Separator = styled.span`
  color: #ffffff;
  font-family: ${theme.fonts.primaryFontBold};
  margin: 0 1em;
  @media ${theme.breakpoints.sm_down} {
    margin-right: 16px;
  }
`;

export const Duration = styled.span`
  color: #ffffff;
  font-family: ${theme.fonts.primaryFontBold};
  @media ${theme.breakpoints.sm_down} {
    margin-right: 16px;
  }
`;
