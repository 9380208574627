import API from "../api/manager";
import config from "../api/config";

const createCartItem = (data) => {
  return API({ method: "PUT", url: config.urls.cart.index, data });
};
const addAllCart = (data) => {
  return API({ method: "PUT", url: config.urls.cart.index + `/all`, data });
};
const removeAllCart = (data) => {
  return API({ method: "DELETE", url: config.urls.cart.index + `/all`, data });
};
const removeCartItem = (id) => {
  return API({ method: "DELETE", url: config.urls.cart.index + `/${id}` });
};
const getCart = () => {
  return API({ method: "GET", url: config.urls.cart.index });
};
const initiate = () => {
  return API({ method: "POST", url: config.urls.cart.index });
};
const capture = (data) => {
  return API({ method: "POST", url: config.urls.cart.capture, data });
};
const getSelectedCartItem = (query) => {
  return API({ method: "GET", url: config.urls.cart.index + query });
};

const generatePerforma = () => {
  return API({ method: "POST", url: config.urls.cart.orders, data: {} });
};

const payPerformaInvoice = (data) => {
  return API({ method: "POST", url: config.urls.cart.order, data });
};

const captureOfflineParties = (paymentId, data) => {
  return API({
    method: "PUT",
    url: `${config.urls.cart.captureOfflineParties}/${paymentId}`,
    data,
  });
};

export default {
  createCartItem,
  removeCartItem,
  addAllCart,
  removeAllCart,
  getCart,
  initiate,
  capture,
  getSelectedCartItem,
  generatePerforma,
  payPerformaInvoice,
  captureOfflineParties,
};
