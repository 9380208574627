import React, { useState } from "react";
import { Center } from "../../../styles/component/style";
import { SignupContainer } from "./styles";
import { Header } from "../../internal/Header/Header";
import Login from "../../internal/Login/Login";
import { useEffect } from "react";
import _ from "lodash";

const passwords = {
  name: "password",
  label: "Password",
  type: "password",
  placeholder: `Enter your Password`,
  required: true,
};

const Index = ({ location = {} }) => {
  const [currentTab, setCurrentTab] = useState("email");
  const [inputs, setInputs] = useState([
    {
      name: "email",
      label: "Registered Email",
      type: "email",
      placeholder: `Enter Your Email`,
    },
    passwords,
  ]);

  useEffect(() => {
    if (currentTab) {
      setInputs([
        {
          name: currentTab,
          label: `Registered ${_.capitalize(currentTab)}`,
          type: currentTab === "mobile" ? "number" : currentTab,
          placeholder: `Enter Your ${_.capitalize(currentTab)}`,
          required: true,
        },
        passwords,
      ]);
    }
  }, [currentTab]);
  return (
    <Header>
      <Center>
        <SignupContainer>
          <Login
            heading={"LOGIN"}
            inputs={inputs}
            {...{ currentTab, setCurrentTab, location }}
          />
        </SignupContainer>
      </Center>
    </Header>
  );
};

export default Index;
