import React from "react";

import { Header } from "../../internal/Header/Header";

const Index = ({ children }) => {
  return (
    <Header showSidebar selectedItem="home">
      {children}
    </Header>
  );
};

export default Index;
