import React, { useState } from "react";
import { Header } from "../../internal/Header/Header";
import ActionBar from "../../common/ActionBar";
import {
  Container,
  ProfilePic,
  Grid,
  LabelValueContainer,
  Label,
  Value,
  BoldLabel,
  ProfileContainer,
  Address,
} from "./styles";
import useUser from "../../../hooks/useUser";
import _ from "lodash";
import moment from "moment";
import { states } from "../../../helpers/constants";
import ProfileModal from "./components/ProfileModal";
import { navigate } from "@reach/router";
import PartyTable from "../../common/PartyTable";
import labels from "../../../helpers/labels.json";

const keys1 = {
  name: labels["user.name"],
  email: labels["user.email"],
  mobile: labels["user.mobile"],
  panNumber: labels["user.pan"],
};

const keys3 = {
  houseNumber: labels["user.house"],
  locality: labels["user.locality"],
  landmark: labels["user.landmark"],
};
const keys4 = {
  city: labels["user.city"],
  state: labels["user.state"],
  pin: labels["user.pin"],
};

const keys2 = [
  {
    key: "dob",
    render: (v) => {
      return moment(v).format("DD/MM/YYYY");
    },
  },
];

function findState(val) {
  const found = states.find((state) => state.value === val);
  if (found) {
    return found.label;
  }
}

const Index = () => {
  const { userObject } = useUser();
  const [modal, setModal] = useState(false);

  const actions = [
    {
      text: labels["actions.change_password"],
      props: { onClick: () => navigate("/dashboard/profile/change-password") },
    },
    {
      text: labels["actions.edit_profile"],
      props: { onClick: () => setModal(true) },
    },
  ];

  return (
    <Header showSidebar>
      <ActionBar actions={actions} />
      <Container>
        <Grid>
          <ProfileContainer>
            <ProfilePic
              alt="profile_img"
              src={
                userObject?.avatarUrl
                  ? userObject.avatarUrl
                  : require("../../../assets/images/userPlaceholder.svg")
              }
            />
          </ProfileContainer>
          {_.keys(keys1).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys1[key]}</Label>
              <Value>{userObject[key] || "-"}</Value>
            </LabelValueContainer>
          ))}
          {keys2.map(({ key, render }, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{key === "dob" ? _.upperCase(key) : key}</Label>
              <Value>{render(userObject[key])}</Value>
            </LabelValueContainer>
          ))}
          <Address style={{ gridColumn: "1 / span 3" }}>
            {labels["user.address"]}
          </Address>
          {_.keys(keys3).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys3[key]}</Label>
              <Value>
                {key === labels["user.state"].toLowerCase()
                  ? findState(userObject[key])
                  : userObject[key]}
              </Value>
            </LabelValueContainer>
          ))}
          {_.keys(keys4).map((key, index) => (
            <LabelValueContainer key={index + key}>
              <Label>{keys4[key]}</Label>
              <Value>
                {key === labels["user.state"].toLowerCase()
                  ? findState(userObject[key])
                  : userObject[key]}
              </Value>
            </LabelValueContainer>
          ))}
        </Grid>
        <BoldLabel>{_.upperCase(labels["my_parties"])}</BoldLabel>
        <PartyTable />
      </Container>
      <ProfileModal {...{ modal, setModal }} />
    </Header>
  );
};

export default Index;
