import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const Container = styled.div`
  padding: 34px 15px;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 34px 43px;
  }
`;

export const TableWrapper = styled.div``;
export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  cursor: pointer;
  padding-left: 29px;
  @media (min-width: 780px) {
    padding: 0;
    margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  }
`;
export const StyledTitle = styled.div`
  cursor: pointer;
  @media (min-width: 780px) {
    width: 200px;
    font-family: ${theme.fonts.primaryFontBold};
  }
`;
