import React, { useEffect } from "react";
import { navigate, useParams } from "@reach/router";
import CaseService from "../../services/CaseService";

export default function Index() {
  const params = useParams();

  useEffect(() => {
    async function endMeeting() {
      try {
        let id = params.id;
        const endMeeting = await CaseService.endMeetingCM(id);
        if (endMeeting) {
          navigate("/");
        }
        return;
      } catch (error) {}
    }
    endMeeting();
  });
  return <></>;
}
