import React from "react";
import styled, { css } from "styled-components";
import theme from "../../../../assets/theme";
import { PrimaryCTAButton } from "../../../common/Buttons";

function CaseCTA({ cta = [], onbuttonClick, disableButton = false }) {
  return (
    <ActionButtonContainer>
      {cta?.length > 0 &&
        cta?.map((button, index) => (
          <ActionButton
            type={"button"}
            key={index}
            danger={button.danger}
            disabled={disableButton}
            onClick={() => onbuttonClick(button.type)}
          >
            {button.label}
          </ActionButton>
        ))}
    </ActionButtonContainer>
  );
}

const ActionButton = styled(PrimaryCTAButton)`
  width: 100%;
  ${({ danger }) =>
    danger &&
    css`
      background-color: #ff3c3c;
      &:focus,
      &:hover {
        background-color: #ff4d4d;
        border: 1px solid #ff3c3c;
      }
      border: 1px solid #ff3c3c;
    `}
  @media ${theme?.breakpoints?.sm_up} {
    width: 280px;
  }
`;

const ActionButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  & button:not(:first-child) {
    margin-left: 20px;
  }
`;
export default CaseCTA;
