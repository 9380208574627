import React from "react";
import styled from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { Tooltip } from "@material-ui/core";

export default function CustomChip({ ...props }) {
  return (
    <ChipContainer>
      <Tooltip title={props.children} placement={"top"}>
        <div>{props.children}</div>
      </Tooltip>
      <img
        src={require("../../../assets/images/crossChip.svg")}
        alt="cross"
        onClick={props.remove}
      />
    </ChipContainer>
  );
}

const ChipContainer = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  font-size: 14px;
  color: ${COLORS.COLOR_DARK};
  padding: 6px 9px;
  border-radius: 7px;
  border: solid 1px #e1e3ee;
  background-color: #ffffff;
  width: 134px;
  margin: 10px 10px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  cursor: pointer;
  & div {
    width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;
