import { Grid, makeStyles } from "@material-ui/core";
import React from "react";
import COLORS from "../../../assets/Colors";
import themes from "../../../assets/theme";
import ProfileCard from "./ProfileCard";

const useStyles = makeStyles((theme) => ({
  profiles: {},

  head: {
    fontSize: "30px",
    color: COLORS.COLOR_DARK,
    font: themes.fonts.primaryFontRegular,
    paddingTop: "15%",
    paddingBottom: "20%",
    textAlign: "center",
  },
}));

function ProfileContainer(props) {
  const classes = useStyles();
  const data = props.data;

  return (
    <Grid
      className={classes.profiles}
      container
      direction="column"
      spacing={3}
      justify="center"
    >
      {data.length > 0 ? (
        data.map((profile) => {
          return (
            <ProfileCard
              item
              key={profile._id}
              neutralType={props.neutralType}
              _id={profile._id}
              name={profile.name}
              image={profile.image}
              lastHeldPosition={profile.lastHeldPosition}
              qualification={profile.qualification}
              yearsOfExperience={profile.yearsOfExperience}
              languagesKnown={profile.languagesKnown}
              affiliatedOrganization={profile.affiliatedOrganisation}
              areasOfExpertise={profile.areasOfExpertise}
              location={profile.location}
            />
          );
        })
      ) : (
        <h1 className={classes.head}> No Data Found</h1>
      )}
    </Grid>
  );
}

export default ProfileContainer;
