import React from "react";
import styled, { css } from "styled-components";
import theme from "../../../../assets/theme";

export default function FilterBar({
  filters = [],
  selectedFilter = "All",
  setSelectedFilter = () => {},
  MTRef,
}) {
  return (
    <FilterContainer>
      {filters.map((filter, index) => (
        <FilterDiv>
          <FilterTab
            key={index}
            onClick={() => {
              if (filter !== selectedFilter) {
                setTimeout(() => {
                  if (MTRef?.current?.onChangePage) {
                    MTRef.current.onChangePage(0, 0);
                  }
                }, 100);
                setSelectedFilter(filter.label);
              }
            }}
            selected={filter.label === selectedFilter}
          >
            {filter.label} {filter.value >= 0 && `(${filter.value})`}
          </FilterTab>
        </FilterDiv>
      ))}
    </FilterContainer>
  );
}

const FilterContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 5px;
  @media (min-width: 815px) {
    min-height: 31px;
    flex-wrap: nowrap;
    width: fit-content;
    min-width: fit-content;
    border-radius: 15.5px;
    background-color: #f4f6ff;
    border: solid 1px #e1e3ee;
  }
`;

const FilterDiv = styled.div`
  width: 50%;
  @media (min-width: 815px) {
    width: auto;
  }
`;

const FilterTab = styled.div`
  min-height: 23px;
  border-radius: 3px;
  width: fit-content;
  margin-bottom: 10px;
  padding: 0 10px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: #acb1c2;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  ${({ selected }) =>
    selected &&
    css`
      color: white;
      background-color: #00838c;
    `};

  @media (min-width: 815px) {
    border-radius: 11.5px;
    margin-bottom: 0;
    height: 23px;
  }
`;
