import styled, { css } from "styled-components";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";

export const PageWrapper = styled.div`
  width: 100%;
  padding: 15px;
  ${({ state }) =>
    state &&
    css`
      max-height: calc(100vh - 130px);
      overflow-y: hidden;
    `}
  @media ${theme?.breakpoints?.sm_up} {
    padding: 15px 53px;
  }
`;

export const Manager = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.md_up} {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const Spacing = styled.div`
  margin-top: 15px;
  @media ${theme?.breakpoints?.sm_up} {
    margin-top: 28px;
  }
`;

export const Block = styled.div`
  width: 100%;
  margin-top: 15px;
  margin-right: 24px;
  &:last-child {
    margin-right: 0;
  }
  @media ${theme?.breakpoints?.sm_up} {
    margin-top: 28px;
  }
  @media ${theme?.breakpoints?.md_up} {
    max-width: 295px;
  }
`;

export const LifeCycleContainer = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  padding: 15px 15px 25px;

  @media ${theme?.breakpoints?.sm_up} {
    padding: 15px 21px 25px;
    border: solid 0.5px #e1e3ee;
    margin-top: 13px;
  }
  @media ${theme?.breakpoints?.lg_up} {
    padding: 0;
    border: none;
    background-color: transparent;
    margin-top: 0;
  }
`;

export const StyledLabel = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 16px;
  line-height: 1.83;
  color: ${COLORS.COLOR_DARK};
  @media ${theme?.breakpoints?.sm_up} {
    font-size: 12px;
  }
`;

export const CaseManagerCard = styled.div`
  width: 100%;
  height: 77px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px 16px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    `}
  @media ${theme?.breakpoints?.md_up} {
    border-radius: 7px;
    border: solid 0.5px #e1e3ee;
  }
`;

export const CaseManagerCardData = styled.div`
  width: 100%;
  height: 77px;
  border-radius: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px 16px;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      & .add_circle {
        cursor: not-allowed !important;
      }
    `}

  @media ${theme?.breakpoints?.md_up} {
    width: 100%;
    border-radius: 7px;
    border: solid 0.5px #e1e3ee;
  }
`;

export const CaseManagerTitle = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 10px;
  line-height: 1.7;
  color: ${COLORS.INPUT_LABEL};
`;
export const Flex = styled.div`
  display: flex;
  align-items: center;
`;
export const CaseManagerIcon = styled.div`
  width: 39px;
  height: 39px;
  border-radius: 50%;
  background-color: rgba(0, 131, 140, 0.13);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const CaseManagerName = styled.div`
  font-size: 12px;
  font-family: ${theme.fonts.primaryFontSemiBold};
  color: ${COLORS.COLOR_DARK};
  padding: 0 6px;
  cursor: pointer;
`;
export const CaseManagerProfile = styled.img`
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: #efefef;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const DropDown = styled.div`
  width: 24px;
  height: 24px;
  margin: 0 17px 0 25px;
  border-radius: 50%;
  background-color: ${COLORS.TABLE_GREY_CELL};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const Icon = styled.img`
  width: 11px;
  height: 6px;
  object-fit: "contain";
  transform: rotate(${({ expanded }) => (expanded ? 180 : 0)}deg);
  transition: 5sec;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  & div {
    margin-left: 35px;
  }
`;

export const Heading = styled.span`
  margin-bottom: 31px;
  font-family: ${theme.fonts.primaryFontExtraBold};
  font-size: ${(props) => (props.size ? props.size : "14px")};
  color: ${COLORS.COLOR_DARK};
  text-transform: capitalize;
  cursor: pointer;
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 67px;
  width: 100%;
  cursor: default;
  & .title-container {
    display: flex;
    cursor: text;
    align-items: center;
    & div:last-child {
      margin-left: 15px;
    }
  }
`;

export const BigScreen = styled.div`
  display: none;
  @media ${theme?.breakpoints?.sm_up} {
    display: block;
  }
`;

export const Title = styled.div`
  margin-left: 15px !important;
  @media ${theme?.breakpoints?.sm_up} {
    margin-left: 21px !important;
  }
`;
