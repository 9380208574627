import React from "react";
import { SecondaryCTAButton } from "../../common/Buttons";
import { WelcomeWrapper, Header } from "./Styles";
import * as ScreenHeader from "../../internal/Header/Header";
import { navigate } from "@reach/router";
import labels from "../../../helpers/labels.json";
import Help from "../../internal/Help";

const Index = () => {
  return (
    <ScreenHeader.Header>
      {/* <Center>
        <WelcomeWrapper>
          <HeaderWrapper>
            <Header>{labels.welcome_user}</Header>
            <Text>{labels.welcome_user_details}</Text>
          </HeaderWrapper> */}
      <WelcomeWrapper>
        <div>
          <Header>{labels.welcome_user}</Header>
          {/* <Text>{labels.welcome_user_details}</Text> */}
        </div>
        <Help />
        <SecondaryCTAButton
          onClick={() => navigate("/dashboard")}
          style={{ margin: "24px" }}
        >
          {labels.skip_this_step}
        </SecondaryCTAButton>
      </WelcomeWrapper>
      {/* </Center> */}
    </ScreenHeader.Header>
  );
};

export default Index;
