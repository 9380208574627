import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const WelcomeWrapper = styled.div`
  margin-top: 87px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%
  overflow: visible;
`;

export const Header = styled.h1`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 0px 20px;
`;

export const Text = styled.p`
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontRegular};
  margin-top: 18px;
  width: 100%
  height: 53px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
`;

export const ContentWrapper = styled.div`
  width: 1102px;
  margin-top: 30px;
  display: flex;
  align-items: space-between;
`;

export const VerticalWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubHead = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  margin: 0 0 10px 10px;
  color: ${COLORS.COLOR_DARK};
  font-size: 16px;
`;

export const VideoCard = styled.div`
  margin: 10px 0;
`;
