import React from "react";
import {
  CardWrapper,
  HeadingContainer,
  Header3,
  Center,
  BackArrow,
} from "../../../styles/component/style";
import { PrimaryCTAButton } from "../Buttons";
import { OtpMessageContainer } from "./Styles";
import ReactCodeInput from "react-code-input";
import { ResentTimer } from "../ResendTimer/ResendTimer";
import { navigate } from "@reach/router";

export const Otp = ({
  heading = "Verify OTP(2/4)",
  disableSubmit = false,
  submitLabel = "Proceed",
  phoneNumber = "87854789957",
  timer = 60,
  isTimmerEnabel = true,
  triggerResent = () => {},
  triggerEdit = () => {},
  numberOfInput = 4,
  inputName = "otp",
  value,
  setValue,
  handleSubmit = () => {},
  showArrow = false,
  codeRef,
}) => {
  const resendOtp = () => {
    triggerResent();
  };

  return (
    <CardWrapper>
      <HeadingContainer>
        {showArrow && (
          <BackArrow
            onClick={() => navigate("/login")}
            src={require("../../../assets/images/backArrow.svg")}
          />
        )}
        <Header3>{heading}</Header3>
      </HeadingContainer>
      <OtpMessageContainer>
        <div className="msg">
          We’ve sent a OTP to {phoneNumber}{" "}
          <span className="link" onClick={triggerEdit}>
            Edit.
          </span>
          <div>Please enter the OTP below in order to proceed</div>
        </div>
        <div className="inputs disabledArrows">
          <ReactCodeInput
            value={value}
            onChange={(e) => setValue(e)}
            name={inputName}
            type="number"
            fields={numberOfInput}
            ref={codeRef}
          />
        </div>
      </OtpMessageContainer>
      <div className="pt20 pb10">
        <PrimaryCTAButton onClick={handleSubmit} disabled={disableSubmit}>
          {submitLabel}
        </PrimaryCTAButton>
      </div>

      {isTimmerEnabel && (
        <Center style={{ padding: 0 }}>
          <ResentTimer
            timer={timer}
            message={` OTP Not Received?`}
            resendOtp={resendOtp}
          />
        </Center>
      )}
    </CardWrapper>
  );
};
