import { FormLabel, makeStyles } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import COLORS from "../../../assets/Colors";
import theme from "../../../assets/theme";

export const CreateCaseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  padding-bottom: 12px;
  padding-left: 13px;
  padding-right: 11px;
  @media ${theme?.breakpoints?.sm_up} {
    padding-left: 46px;
    padding-right: 0;
    margin-top: 0;
    padding-bottom: 0;
  }
`;

export const Container = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.PRIMARY_WHITE};
  margin: 40px 15px;
  padding-top: 1px;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 0;
    padding-top: 0;
    border-radius: 0;
    background-color: transparent;
  }
`;

export const H1 = styled.h1`
  font-family: ${theme.fonts.primaryFontExtraBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 18px;
  font-weight: 800;
  margin: 8px 0 21px 0;
  @media ${theme?.breakpoints?.sm_up} {
    margin: 34px 0 21px 0;
  }
`;

export const H2 = styled.h2`
  font-family: ${theme.fonts.primaryFontBold};
  color: ${COLORS.COLOR_DARK};
  font-size: 12px;
`;
export const Subheading = styled.span`
  max-width: 576px;
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 12px;
  font-weight: bold;
  line-height: 1.83;
  margin-bottom: 17px;
`;

export const StyledForm = styled.form`
  @media ${theme?.breakpoints?.sm_up} {
    // width: 436px;
    width: 505px;
  }
  & .heading {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    & .delete {
      height: 20px;
      width: 20px;
      margin-bottom:15px;
      cursor: pointer;
      @media ${theme?.breakpoints?.sm_up} {
        margin: 34px 0 21px 0;
      }
    }
`;

export const StyledFormElementsWrapper = styled.div`
  margin-bottom: 28px;
  max-width: 436px;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .template {
    margin-top: 0px;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
  @media ${theme?.breakpoints?.sm_down} {
    & .template {
      margin-top: 15px;
    }
  }
`;

export const StyledFormElementsWrapper1 = styled.div`
  margin-bottom: 28px;
  & .link {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
  }
  & .waring-msg {
    color: ${COLORS.WARNING_COLOR};
    font-size: 10px;
    white-space: nowrap;
  }
  & .mediator-type {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: column !important;
      & .MuiFormControlLabel-root {
        padding: 10px 0px;
      }
    }
  }
`;

export const DescriptionWrapper = styled.div`
  margin-bottom: 13px;
`;

export const useStyles = makeStyles(() => ({
  root: {},
}));

export const SampleText = styled.div`
  font-family: ${theme.fonts.primaryFontRegular};
  color: ${COLORS.INPUT_LABEL};
  font-size: 10px;
  font-weight: 600;
  line-height: 1.7;
  color: #949ab0;
  margin-bottom: 3px;
`;

export const TextCenter = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  & h2 {
    margin-bottom: 10px;
  }
`;

export function StyledLabel({ children, required }) {
  return (
    <FormLabel
      style={{
        fontFamily: theme.fonts.primaryFontSemiBold,
        fontSize: 10,
        color: COLORS.INPUT_LABEL,
      }}
    >
      {children}
      {required && <span className="required-star"> *</span>}
    </FormLabel>
  );
}

export function StyledLabelNote({ children, required }) {
  return (
    <FormLabel
      style={{
        fontFamily: theme.fonts.primaryFontSemiBold,
        fontSize: 10,
        color: COLORS.COLOR_DARK,
      }}
    >
      {children}
      {required && <span className="required-star"> *</span>}
    </FormLabel>
  );
}

export const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media ${theme?.breakpoints?.sm_up} {
    width: auto;
  }
`;

export const DeleteIcon = styled.img`
  margin-left: 20px;
  user-select: none;
  object-fit: contain;
  cursor: pointer;
`;

export const Button = styled.div`
  width: 50%;
`;

export const AccordionContainer = styled.div`
  margin-bottom: 18px;
  @media ${theme?.breakpoints?.sm_up} {
    width: 650px;
  }
  & .accordion-root {
    box-shadow: none;
    border-radius: 4px;
    border: solid 0.5px #e1e3ee;
    background-color: #ffffff;
  }
  & .accordion-content {
    margin: 0;
    padding: 16px 14px;
  }
  & .accordion-icon {
    margin-right: 5px;
  }
  & .custom-table {
    width: 100%;
  }
`;

export const AccordionHeading = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 16px;
  color: #293461;
`;

export const HyperLink = styled.div`
  font-family: ${theme.fonts.primaryFontSemiBold};
  font-size: 14px;
  color: ${COLORS.BTN_GREEN};
  text-decoration: underline;
  margin-right: ${({ marginRight }) => (marginRight ? marginRight : "0px")};
  cursor: pointer;
  @media ${theme?.breakpoints?.sm_up} {
    width: 90px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const StyledTextSemi = styled.a`
  font-family: ${theme.fonts.primaryFontSemiBold};
`;

export const StyledText = styled.a`
  font-family: ${theme.fonts.primaryFontExtraBold};
`;
