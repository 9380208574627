import React, { useState } from "react";
import PartyDetails from "../../internal/PartyDetails/PartyDetails";
import PartyDetailsModal from "../../internal/PartyDetails/modal";
import styled, { css } from "styled-components";

const Index = ({ partyId }) => {
  const [state, setState] = useState();
  const [party, setParty] = useState({});

  return (
    <StyledContainer state={state}>
      <PartyDetails createAgent={setState} {...{ party, setParty, partyId }} />
      <PartyDetailsModal {...{ state, setState, party, setParty }} />
    </StyledContainer>
  );
};

export default Index;

const StyledContainer = styled.div`
  ${({ state }) =>
    state &&
    css`
      max-height: calc(100vh - 74px);
      overflow-y: hidden;
    `}
`;
