import labels from "../../../helpers/labels.json";

export const basicLabels = {
  kind: labels["table.party_type"],
  id: labels["table.party_id"],
  name: labels["table.party_name"],
  documentKind: labels["party.docType"],
  documentNumber: "DOCUMENT NUMBER",
};

export const documentLabels = {
  pan: labels["party.pan_card"],
  aadhaar: labels["party.aadhaar_card"],
  cin: labels["party.cin_number"],
  voter: labels["party.voter"],
  passport: labels["party.passport"],
};
