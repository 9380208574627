import React from "react";
import { CustomInputFieldBulkUpload } from "../FormInputs";
import theme from "../../../assets/theme";
import { FormLabel } from "@material-ui/core";
import CustomSelect from "../CustomSelect/CustomSelect";
import COLORS from "../../../assets/Colors";

export default function FormField({
  touched,
  errors,
  input,
  values,
  handleChange,
  handleBlur,
  menuItems = [],
  white = false,
  multiline,
  disabled = false,
  showId = false,
}) {
  const { name, label, type, placeholder, required } = input;
  const visibility = false;

  return (
    <div
      className="form-fields"
      style={{ width: "100%", paddingTop: 5, display: "flex" }}
    >
      <FormLabel
        error={errors[name] && touched[name]}
        style={{
          fontFamily: theme.fonts.primaryFontSemiBold,
          fontSize: 12,
          color: COLORS.COLOR_DARK,
          display: "flex",
          alignItems: "center",
          width: 180,
        }}
      >
        {label}
        {required && <span className="required-star"> *</span>}
      </FormLabel>
      {type === "select" ? (
        <CustomSelect
          white={white}
          id={name}
          menuItemValues={menuItems}
          name={name}
          value={values[name]}
          onChange={handleChange}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          onBlur={handleBlur}
          style={{ marginTop: 6 }}
          disabled={disabled}
          showId={showId}
        />
      ) : (
        <CustomInputFieldBulkUpload
          className={
            white && multiline
              ? "input-white input-height-auto"
              : white
              ? "input-white"
              : multiline
              ? "input-height-auto"
              : ""
          }
          multiline={multiline}
          row={4}
          autoComplete={"new-password"}
          autoFocus={name === "fullname"}
          type={type === "password" ? (visibility ? "text" : type) : type}
          value={values[name]}
          error={errors[name] && touched[name]}
          helperText={touched[name] && errors[name]}
          id={name}
          placeholder={placeholder}
          name={name}
          variant="outlined"
          onChange={handleChange}
          onBlur={handleBlur}
          style={{ marginTop: 6, marginBottom: 10 }}
          disabled={disabled}
          min={0}
        />
      )}
    </div>
  );
}
