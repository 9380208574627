import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  Drawer,
  Checkbox,
  withStyles,
  Menu,
  MenuItem,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import theme from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import { getErrorMessage, parseTimeStamp } from "../../../helpers/functions";
import { useSnackbar } from "notistack";
import useLoader from "../../../hooks/useLoader";
import CaseService from "../../../services/CaseService";
import moment from "moment";
import MoreOrLess from "../../common/MoreOrLess";
import { CheckboxContainer, CustomInputField } from "../../common/FormInputs";
import { PrimaryCTAButton } from "../../common/Buttons";
import _ from "lodash";
import queryString from "query-string";
import useUser from "../../../hooks/useUser";
import { Group } from "@material-ui/icons";
import { sanitize } from "dompurify";

const useStyles = makeStyles((themes) => ({
  paper: {
    boxShadow: "none",
    border: "solid 0.5px #e1e3ee",
  },
  menuRoot: {
    fontFamily: theme.fonts.primaryFontSemiBold,
    fontSize: 12,
    color: COLORS.COLOR_DARK,
    display: "flex",
    flex: 1,
    alignItems: "center",
  },
}));

export default function ViewNotesModal({
  modal,
  setModal,
  id,
  type = "Mediator",
  disabled = false,
  partyList,
}) {
  const [notes, setNotes] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const [note, setNote] = useState("");
  const ref = useRef();
  const [currentOwnerParty, setCurrentOwnerParty] = useState("");
  const { userObject } = useUser();
  const [shared, setShared] = useState("");
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const addNotes = async (value) => {
    try {
      setLoader({ state: true, message: "Adding notes..." });
      const payload = {
        content: value,
        visibilityPartyId: shared ? currentOwnerParty : null,
      };
      const res = await CaseService.addNotes(payload, id);
      if (res?.message) {
        enqueueSnackbar(res?.message, {
          variant: "success",
        });
        notes.unshift(res?.newNote);
        setNote("");
        ref.current.scrollTop = 0;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  useEffect(() => {
    async function getAllNotes(id) {
      try {
        setLoader({ state: true, message: "loading Notes..." });
        let params = {
          page: 1,
          perPage: 1000,
        };
        let stringParams = "";
        const filterSting = "me";
        if (!_.isEmpty(params)) {
          stringParams = `?${queryString.stringify(params)}&${filterSting}`;
        }
        const res = await CaseService.getNotes(id, stringParams);
        if (res?.data?.length) {
          setNotes(res?.data);
        }
        if (res) {
          setShared(res?.shared);
        }
      } catch (error) {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, {
          variant: "error",
        });
      } finally {
        setLoader({ state: false });
      }
    }
    if (id && modal) {
      getAllNotes(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, modal]);

  const sendNotestoAgent = async (currentOwnerParty) => {
    try {
      setLoader({ state: true, message: "Updating Status..." });
      const payload = {
        visibilityPartyId: !shared ? currentOwnerParty : null,
      };
      const response = await CaseService.sendNotestoAgent(payload, id);
      if (response) {
        enqueueSnackbar(response?.message, {
          variant: "success",
        });
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
    }
  };

  useEffect(() => {
    if (partyList?.length) {
      let currentPartyId = "";
      partyList.forEach((el) => {
        el.party.agents.forEach((agent) => {
          if (agent?.userId === userObject?.id) {
            currentPartyId = el.party?.id;
          }
        });
      });
      setCurrentOwnerParty(currentPartyId);
    }
  }, [partyList, userObject]);

  const filter = ["both", "shared", "me"];

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const getAllNotes = async (id, item) => {
    try {
      setLoader({ state: true, message: "loading Notes..." });
      let params = {
        page: 1,
        perPage: 1000,
      };
      let stringParams = "";
      const filterSting = item;
      if (!_.isEmpty(params)) {
        stringParams = `?${queryString.stringify(
          params
        )}&filter=${filterSting}`;
      }
      const res = await CaseService.getNotes(id, stringParams);
      if (res?.data?.length) {
        setNotes(res?.data);
      }
      if (res) {
        setShared(res?.shared);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      enqueueSnackbar(message, {
        variant: "error",
      });
    } finally {
      setLoader({ state: false });
      setAnchorEl(null);
    }
  };

  return (
    <Drawer
      anchor={"right"}
      open={modal}
      onClose={() => setModal(false)}
      disableRestoreFocus={true}
    >
      <DrawerContainer role="presentation">
        <HeadingContainer>
          <Heading>Scratch Pad</Heading>
          {notes?.length ? (
            <ContainerIcon>
              <CheckboxContainer>
                <Tooltip title={shared ? "unshare" : "share"} placement="top">
                  <CustomCheckBox
                    inputRef={ref}
                    checked={shared}
                    onChange={(ev) =>
                      setShared(ev?.target?.checked) ||
                      sendNotestoAgent(currentOwnerParty)
                    }
                  />
                </Tooltip>
              </CheckboxContainer>
              <Group
                style={{
                  cursor: "pointer",
                  fill: COLORS.BTN_GREEN,
                }}
                onClick={handleClick}
              />
            </ContainerIcon>
          ) : null}
          {/* <Group
            style={{
              marginLeft: notes?.length ? "6px" : "20px",
              cursor: "pointer",
              fill: COLORS.BTN_GREEN,
            }}
          /> */}
          <Menu
            id="comment-send-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            classes={{ paper: classes.paper }}
          >
            <Label>Visibility options</Label>
            {filter
              ? filter?.map((item, index) => (
                  <MenuItem
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={classes.menuRoot}
                    onClick={() => getAllNotes(id, item)}
                  >
                    <NameMenu title={item}>
                      {_.capitalize(
                        item === "shared"
                          ? "Shared with me"
                          : item === "me"
                          ? "My notes"
                          : item
                      )}
                    </NameMenu>
                    {/* <MenuLabel>
                      {_.capitalize(
                        item === "shared"
                          ? "Shared with me"
                          : item === "me"
                          ? "My notes"
                          : item
                      )}
                    </MenuLabel> */}
                  </MenuItem>
                ))
              : ""}
          </Menu>
          <CloseModal
            onClick={() => setModal(false)}
            src={require("../../../assets/images/closeModal.svg")}
          />
        </HeadingContainer>
        <NoteContainer ref={ref}>
          {notes?.length > 0 ? (
            <div>
              {notes?.map((note, index) => (
                <div className="note" key={index}>
                  <div className="time">
                    {moment(parseTimeStamp(note?.created_at)).format(
                      "DD/MM/yyyy"
                    )}{" "}
                    AT{" "}
                    {moment(parseTimeStamp(note?.created_at)).format("hh:mm a")}
                    {note?.owner?.name ? (
                      <OwnerContainer>({note?.owner?.name})</OwnerContainer>
                    ) : null}
                  </div>
                  <div className="content">
                    <MoreOrLess
                      Paragraph={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sanitize(note?.content),
                          }}
                        ></div>
                      }
                      className="content"
                    />{" "}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <Height>
              <EmptyMsg>There are no notes added yet.</EmptyMsg>
            </Height>
          )}
        </NoteContainer>
        <EditorContainer>
          <div style={{ width: "70%" }}>
            <CustomInputField
              variant="outlined"
              className={"input-height-auto"}
              style={{ marginTop: 6 }}
              multiline={true}
              rows={5}
              placeholder="Type something here…"
              maxRows={5}
              name={"note"}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <div style={{ width: "30%" }}>
            <PrimaryCTAButton
              style={{ width: 115 }}
              onClick={() => addNotes(note)}
              disabled={!note?.trim()}
            >
              Add Note
            </PrimaryCTAButton>
          </div>
        </EditorContainer>
      </DrawerContainer>
    </Drawer>
  );
}

const DrawerContainer = styled.div`
  width: 100vw;
  height: 100%;
  background-color: white;
  outline: none;
  @media (min-width: 640px) {
    width: 618px;
  }
`;

const EditorContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  background-color: ${COLORS.ADD_NOTE_TEXT_AREA};
  bottom: 0px;
  width: 100%;
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  & .MuiOutlinedInput-multiline {
    background-color: transparent !important;
    padding: 10px 30px !important;
  }
`;

const HeadingContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 20px;
  padding-left: 38px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  position: relative;
  border-bottom: 1px solid ${COLORS.INPUT_BORDER};
`;

const CloseModal = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 30px;
`;

const Heading = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${COLORS.COLOR_DARK};
  font-family: ${theme.fonts.primaryFontExtraBold};
`;

const NoteContainer = styled.div`
  padding: 30px;
  overflow: auto;
  height: calc(100% - 210px);
  & .note {
    margin: 15px 0px;
    & .time {
      font-family: ${theme.fonts.primaryFontSemiBold};
      font-size: 10px;
      display: flex;
      font-weight: 600;
      line-height: 1.7;
      color: ${COLORS.INPUT_LABEL};
    }
    & .content {
      font-family: ${theme.fonts.primaryFontSemiBold};
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71;
      color: ${COLORS.COLOR_DARK};
      word-break: break-word;
      & .readMoreText {
        color: ${COLORS?.BTN_GREEN} !important;
      }
    }
  }
`;

const EmptyMsg = styled.div`
  color: ${COLORS.COLOR_DARK};
  font-weight: 500;
  letter-spacing: 0.1px;
  font-size: 24px;
  margin: 20px 0px;
  font-family: ${theme.fonts.primaryFontBold};
  text-align: center;
`;

const CustomCheckBoxStyle = {
  root: {
    "& .MuiSvgIcon-root": {
      color: `${COLORS.BTN_GREEN} !important`,
    },
  },
  checked: {
    color: `${COLORS.BTN_GREEN} !important`,
  },
};

export const CustomCheckBox = withStyles(CustomCheckBoxStyle)(Checkbox);

const Height = styled.div`
  height: 50vh;
  @media (min-width: 780px) {
    height: auto;
  }
`;

const Label = styled.div`
  font-family: ${theme.fonts.primaryFontBold};
  font-size: 10px;
  letter-spacing: 0.33px;
  color: #acb1c2;
  padding: 0 16px 5px;
`;

const NameMenu = styled.div`
  max-width: 100px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

// const MenuLabel = styled.div`
//   color: ${COLORS.INPUT_LABEL};
//   font-family: ${theme.fonts.primaryFontSemiBold};
//   font-size: 10px;
//   display: flex;
//   flex: 1;
//   justify-content: flex-end;
//   margin-left: 10px;
// `;

const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
`;

const OwnerContainer = styled.div`
  color: ${COLORS.BTN_GREEN};
  margin-left: 3px;
`;
