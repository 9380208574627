import React, { Fragment, useState, useEffect } from "react";
import ActionBar from "./ActionBar";
import _ from "lodash";
import { navigate, useLocation } from "@reach/router";
import styled from "styled-components";
import themes from "../../../assets/theme";
import COLORS from "../../../assets/Colors";
import useLoader from "../../../hooks/useLoader";
// import { useSnackbar } from "notistack";
import CaseService from "../../../services/CaseService";
// import { getErrorMessage } from "../../../helpers/functions";
import theme from "../../../assets/theme";
import TranscriptEditor from "../../transcripts/TranscriptEditor/index";

const TranscriptEdit = (id) => {
  const history = useLocation();
  const resolutionKind = history.search.replace("?caseType=", "");
  const breadcrumbs = [
    "Cases",
    `${_.capitalize(resolutionKind)} - ${id?.id}`,
    "Transcript",
  ];
  // const { enqueueSnackbar } = useSnackbar();
  const { setLoader } = useLoader();
  const caseId = id?.id;
  const [data, setData] = useState([
    {
      id: "",
      start: "",
      end: "",
      text: "",
      speaker: "",
      confidence: "",
      transcriptionFlag: "",
    },
  ]);
  const [mediaUrl, setMediaUrl] = useState();
  const [meetingId, setMeetingId] = useState();
  const [refreshDetails, setRefreshDetails] = useState(true);
  const [render, setRender] = useState(false);
  const [checked, setChecked] = useState(false);
  const autoRefresh = true;
  let LiveTranscription = true;

  const onBreadcrumbClick = (selected) => {
    switch (_.snakeCase(selected)) {
      case `${resolutionKind}_${id?.id}`:
        navigate(`/dashboard/cases/${id?.id}?caseType=${resolutionKind}`);
        break;
      case "cases":
        navigate(`/dashboard/cases?caseType=${resolutionKind}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    async function getLiveTranscriptionData(caseId) {
      try {
        !checked &&
          setLoader({ state: true, message: "Fetching Transcription..." });
        const res = await CaseService.getLiveTranscriptionData(caseId);
        if (res) {
          setData(
            res?.transcriptionData?.words?.map((el) => {
              return {
                id: el?.id,
                end: el?.end,
                confidence: el?.confidence,
                text: el?.text ? el?.text : "",
                start: el?.start,
                speaker: el?.speaker,
                transcriptionFlag: el?.transcriptionFlag,
              };
            }),
          );
          setMeetingId(res?.meetingId);
          setMediaUrl(res);
        } else {
          setMediaUrl();
        }
      } catch (error) {
        console.log(error);
      } finally {
        !checked && setLoader({ state: false });
        setRender(true);
      }
    }
    if (refreshDetails) {
      getLiveTranscriptionData(caseId);
      setRefreshDetails(false);
    }
  }, [refreshDetails]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checked) {
      const timeInterval = setInterval(
        () => {
          setRefreshDetails(true);
        },
        mediaUrl?.autoRefreshTimeInterval
          ? mediaUrl?.autoRefreshTimeInterval * 1000
          : 6 * 1000,
      );
      return () => clearInterval(timeInterval);
    }
  }, [checked]); // eslint-disable-line react-hooks/exhaustive-deps

  const actions = [
    {
      text: "Refresh",
      props: {
        onClick: () => setRefreshDetails(true),
      },
      hidden: false,
    },
  ];

  return (
    <Fragment>
      <ActionBar
        {...{
          breadcrumbs,
          onBreadcrumbClick,
          actions,
          checked,
          setChecked,
          autoRefresh,
          LiveTranscription,
        }}
      />
      <Container>
        <div>
          {mediaUrl?.transcriptionData ? (
            <TranscriptEditor
              {...{
                caseId,
                meetingId,
                data,
                refreshDetails,
                render,
                setRender,
              }}
              mediaUrl={mediaUrl?.audioFileUrl}
              title={history?.state?.title}
              setData={setData}
            />
          ) : (
            <StyledTableEmptyBlock>
              <h2>
                {
                  "Welcome to IIAC live transcription services. Your session has either not started or your live transcript is currently being generated."
                }
              </h2>
            </StyledTableEmptyBlock>
          )}
        </div>
      </Container>
    </Fragment>
  );
};

export default TranscriptEdit;

export const Container = styled.div`
  padding: 4px 15px;
  display: flex;
  flex-direction: column;
  @media ${theme?.breakpoints?.sm_up} {
    padding: 4px 43px;
  }
`;

export const HeadingContainer = styled.span`
  margin-bottom: 31px;
  font-family: ${themes.fonts.primaryFontSemiBold};
  font-size: ${(props) => (props.size ? props.size : "13px")};
  color: ${COLORS.BTN_GREEN};
  // text-transform: uppercase;
  cursor: pointer;
  & .topSection {
    background: ${COLORS.BOX_DARKGREEN};
  }
`;

const StyledTableEmptyBlock = styled.div`
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-40%, -40%);
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    color: ${COLORS.COLOR_DARK};
    font-weight: 500;
    letter-spacing: 0.1px;
    font-size: 20px;
    margin: 0;
    text-align: center;
    font-family: ${theme.fonts.primaryFontBold};
  }
  p {
    margin: 10px auto 30px;
    font-family: ${theme.fonts.primaryFontRegular};
    color: ${COLORS.COLOR_DARK};
  }
  a {
    color: ${COLORS.BTN_GREEN};
    font-family: ${theme.fonts.primaryFontRegular};
  }
`;
